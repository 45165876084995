<template>
  <div class="history">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Paket Wisata</div>
    </div>

    <div class="body">
      <div class="paket">
        <div class="img">
          <img :src="paket.gambar" alt="" />
        </div>
        <div class="paket-info">
          <h3>{{ paket.nama }}</h3>
          <p>Rp {{ paket.harga_jual | number }} / pax</p>
        </div>
        <div class="paket-desc" v-html="paket.keterangan"></div>
      </div>
      <div class="booking">
        <h3>Booking Sekarang</h3>
        <form @submit.prevent="booking">
          <div class="form-group">
            <label for="">Jumlah Pax</label>
            <input type="number" v-model="bookingData.jumlah_pax" />
          </div>
          <div class="form-group">
            <label for="">Tgl Tour</label>
            <input type="date" v-model="bookingData.tgl_wisata" />
          </div>
          <div>
            <table>
              <tr>
                <td>Total</td>
                <td align="right">
                  Rp
                  {{
                    bookingData.jumlah_pax
                      ? bookingData.jumlah_pax * paket.harga_jual
                      : 0 | number
                  }}
                </td>
              </tr>
            </table>
          </div>
          <div>
            <button type="submit" class="btn-primary" :disabled="loadingSubmit">
              Booking
            </button>
          </div>
        </form>

        <div class="notif" v-if="sukses">
          <p>
            Berhasil booking paket wisata. Silakan hubungi Customer Service
            Abbetama untuk konfirmasi.
          </p>
          <a href="https://wa.me/6285643153489"> Hubungi CS </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "../api/Api";
import moment from "moment";

export default {
  name: "Search",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loading: true,
      loadingSubmit: false,
      paket: {},
      bookingData: {},
      sukses: false
    };
  },
  methods: {
    moment,
    loadData() {
      api
        .get("/v1/abbetama/WisataController/detail/" + this.$route.params.id)
        .then((r) => {
          this.paket = r.data;
        });
    },
    booking() {
      this.bookingData.id_paket = this.paket.id;
      this.bookingData.id_siswa = this.$store.state.settings.user.id;

      if (!this.bookingData.jumlah_pax || !this.bookingData.tgl_wisata) {
        alert("Input jumlah pax dan tgl tour");
        return;
      }

      this.loadingSubmit = true;
      api
        .post("/v1/abbetama/WisataController/booking/", this.bookingData)
        .then((r) => {
          if (r.valid) {
            this.sukses = true
          }
          this.loadingSubmit = false;
        })
        .catch((e) => {
          this.loadingSubmit = false;
        });
    },
  },
  mounted() {
    const id_user = this.$store.state.settings.user.id;
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.history {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    padding: 15px;
    .paket {
      img {
        width: 100%;
      }
      .paket-info {
        h3,
        p {
          margin: 0;
        }
        p {
          margin-top: 5px;
          color: #777;
        }
      }
      .paket-desc {
        font-size: 14px;
      }
    }
    .booking {
      margin-top: 30px;
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 15px;
        font-weight: bold;
      }
    }
  }

  .notif {
    background-color: #82cc70;
    padding: 15px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    p {
      margin: 0;
    }
    a {
      display: block;
      color: #333;
      text-decoration: none;
      background-color: #fff;
      padding: 15px;
      border-radius: 4px;
      margin-top: 15px;
      text-align: center;
    }
  }
}
</style>
