<template>
  <div class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="ionicon"
      viewBox="0 0 512 512"
    >
      <title>Arrow Back</title>
      <path
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="60"
        d="M244 400L100 256l144-144M120 256h292"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Clock",
};
</script>

<style scoped lang="scss">
.icon {
  width: 24px;
}
</style>
