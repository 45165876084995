const state = {
    sidebarOpen: false,
    user: {}
}

const getters = {
    baseUrl: state => state.settings || 'NotSet',
}

const mutations = {
    setSidebarOpen(state, val) {
        state.sidebarOpen = val
    },
    setUser(state, user) {
        state.user = user
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
