<template>
  <div class="pembayaran">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Pembayaran</div>
    </div>

    <div class="loading" v-if="loading">
      <Loading />
    </div>

    <div class="body" v-else>
      <p>Status Pembayaran</p>
      <div class="card">
        <h1>{{ data.status_pembayaran }}</h1>
        <small v-if="data.history_pembayaran.length > 0"
          >Divalidasi pada
          {{
            moment(data.history_pembayaran[0].by_input).format("DD MMM YYYY")
          }}</small
        >

        <div
          class="lunas"
          v-if="data.status_pembayaran.toUpperCase() == 'LUNAS'"
        >
          <p>No Lunas</p>
          <h3>{{ data.history_pembayaran[0].by_nolunas }}</h3>
        </div>
      </div>

      <p style="margin-bottom: 0">History Pembayaran</p>
      <ul class="history">
        <li v-for="(item, index) in data.history_pembayaran" :key="index">
          <div>
            <p>{{ item.by_ket }}</p>
            <small>{{ moment(item.by_tgl).format("DD MMM YYYY") }}</small>
          </div>
          <div>
            <span>{{ item.by_nom | number }}</span>
            <small> {{ item.by_nomfix | number }}</small>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";
import Receipt from "./icons/Receipt.vue";

import * as api from "../api/Api";
import moment from "moment";

export default {
  name: "Search",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      fiturAktif: true,
      loading: true,
      data: [],
    };
  },
  methods: {
    moment,
    loadData() {
      this.loading = true;
      api
        .get("/v1/siswa/PembayaranController/get?id=" + this.$user.id)
        .then((r) => {
          this.loading = false;

          if (r.valid) {
            this.data = r.data;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.pembayaran {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    border-bottom: 1px solid #ccc;

    > a {
      display: flex;
      justify-content: flex-end;
      color: #fff;
    }
  }

  .loading {
    display: flex;
    height: 70vh;
    justify-content: center;
    align-items: center;
  }

  .body {
    padding: 15px;

    p {
      color: #2c9cff;
    }
    .card {
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 30px 20px;
      margin-bottom: 30px;

      .lunas {
        margin-top: 20px;
        p,
        h3 {
          margin: 0;
        }
        h3 {
          margin-top: 6px;
          font-weight: 500;
        }
      }
      h1 {
        margin: 0;
      }
      small {
        color: #777;
      }
    }
    .history {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 20px 0;

        > div {
          &:last-child {
            width: 150px;
            text-align: right;
          }
        }

        p {
          color: #333;
          margin: 0;
          font-size: 15px;
        }
        small {
          color: #777;
          display: block;
          margin-top: 5px;
        }

        span {
          color: #2c9cff;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
