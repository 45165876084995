<template>
  <div class="history">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Paket Wisata</div>
      <router-link to="/history-booking-wisata" v-if="fiturAktif">
        <receipt />
      </router-link>
    </div>

    <div class="body">
      <div v-if="!fiturAktif">
        <p style="text-align: center; margin-top: 100px">
          Fitur belum aktif
        </p>
      </div>
      <div
        v-else
        class="paket"
        v-for="(item, index) in paket"
        :key="index"
        @click="$router.push('/paket-wisata/' + item.id)"
      >
        <div class="img">
          <img :src="item.gambar" alt="" />
        </div>
        <div class="paket-info">
          <h3>{{ item.nama }}</h3>
          <p>Rp {{ item.harga_jual | number }} / pax</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";
import Receipt from "./icons/Receipt.vue";

import * as api from "../api/Api";
import moment from "moment";

export default {
  name: "Search",
  components: {
    Back,
    Loading,
    Receipt,
  },
  data() {
    return {
      fiturAktif: true,
      loading: true,
      paket: [],
    };
  },
  methods: {
    moment,
    cekFiturFlag() {
      api.get("/v1/FiturFlagController/get?fitur=paket_wisata").then((r) => {
        if (r.valid) {
          // this.fiturAktif = r.data.status == 1;
        }
      });
    },
    loadData() {
      api.get("/v1/abbetama/WisataController/getPaket").then((r) => {
        this.paket = r.data;
      });
    },
  },
  mounted() {
    this.cekFiturFlag();
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.history {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    border-bottom: 1px solid #ccc;

    > a {
      display: flex;
      justify-content: flex-end;
      color: #fff;
    }
  }

  .body {
    padding: 15px;
    .paket {
      padding-bottom: 15px;
      margin-bottom: 30px;
      border-bottom: 1px solid #ccc;
      img {
        width: 100%;
      }
      .paket-info {
        h3,
        p {
          margin: 0;
        }
        p {
          margin-top: 5px;
          color: #777;
        }
      }
    }
  }
}
</style>
