<template>
  <div class="page">
    <div class="form">
      <p>
        <img src="./../assets/icons/siswa.png" style="height:60px;" />
      </p>
      <h3>Aktivasi Siswa Get Siswa</h3>

      <p style="font-size: 14px;width: 340px; margin: 0 auto" v-if="false">
        Siswa Get Siswa merupakan program pelatihan nyata dari mata diklat Digital marketing untuk mampu mengaplikasikan
        pemasaran dengan keuntungan mendapatkan penghasilan tambahan
      </p>

      <form @submit.prevent="aktivasi()">
        <div class="form-group">
          <label>Masukkan email</label>
          <input type="email" v-model="param.email" required>
        </div>
        <div class="form-group">
          <label>Masukkan no hp</label>
          <input type="tel" v-model="param.no_hp" required>
        </div>
        <div class="form-group">
          <label>Nama Bank</label>
          <input type="text" v-model="param.bank" required>
        </div>
        <div class="form-group">
          <label>No Rekening</label>
          <input type="tel" v-model="param.no_rekening" required>
        </div>
        <div class="form-group">
          <label>Pemilik Rekening</label>
          <input type="text" v-model="param.atas_nama" required>
        </div>
        <button type="submit" class="btn btn-primary" :disabled="loading">
          Aktivasi
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { rawPost } from '@/api/Api';

export default {
  data() {
    return {
      param: {
        no_hp: this.$user.no_hp
      },
      loading: false,
    }
  },
  methods: {
    async aktivasi() {
      this.loading = true;

      const param = {
        id_siswa: this.$user.id,
        nama: this.$user.nama,
        email: this.param.email,
        no_hp: this.param.no_hp,
        bank: this.param.bank,
        no_rekening: this.param.no_rekening,
        atas_nama: this.param.atas_nama,
        password: this.$user.nama.substring(0, 2) + this.$user.id
      }
      const res = await rawPost('https://pendaftaran.pspp-integrated.com/v1/aktivasi/siswaGetSiswa', param);

      if (res.valid) {
        alert('Link untuk aktivasi sudah dikirim ke email Anda');
      }

      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h3,
  p {
    text-align: center
  }

  p {
    max-width: 360px;
  }

  form {
    margin: 60px auto 0;
    width: 300px;
  }
}
</style>