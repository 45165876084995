<template>
  <div class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="ionicon"
      viewBox="0 0 512 512"
    >
      <title>Calendar Clear</title>
      <rect
        fill="none"
        stroke="#777"
        stroke-linejoin="round"
        stroke-width="32"
        x="48"
        y="80"
        width="416"
        height="384"
        rx="48"
      />
      <path
        fill="none"
        stroke="#777"
        stroke-linejoin="round"
        stroke-width="32"
        stroke-linecap="round"
        d="M128 48v32M384 48v32M464 160H48"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Calendar",
};
</script>

<style scoped lang="scss">
.icon {
  width: 25px;
}
</style>
