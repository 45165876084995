<template>
  <div class="history">
    <div class="navbar">
      <div @click="$router.push('/tiket')">
        <back />
      </div>
      <div>History Booking</div>
    </div>

    <div class="body">
      <div v-if="loading">
        <p style="text-align: center;">
          <loading />
        </p>
      </div>
      <ul v-else>
        <li v-for="(item, index) in booking" :key="index" @click="to(item)">
          <div>
            <b>{{ $validateMaskapai(item.flight_number, item.airline) }}</b
            ><br />
            {{ item.kota_asal }} - {{ item.kota_tujuan }} <br />
            <small>{{ moment(item.tgl_terbang).format("DD MMM YYYY") }}</small>
          </div>
          <div>
            <div>
              <span class="belum-bayar" v-if="item.status_issued != 1 && item.status_bayar != 1">Belum Lunas</span>
              <span class="" v-if="item.status_issued != 1 && item.status_bayar == 1">LUNAS</span>
              <span class="" v-if="item.status_issued == 1">ISSUED</span>
              <b>Rp {{ item.grand_total | number }}</b>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "./../api/FlightApi";
import moment from "moment";

export default {
  name: "Search",
  components: {
    Back,
    Loading
  },
  data() {
    return {
      loading: true,
      booking: [],
    };
  },
  methods: {
    moment,
    to(item) {
      setTimeout(() => {
        this.$router.push("/history/" + item.id);
      }, 150);
    },
  },
  mounted() {
    const id_user = this.$store.state.settings.user.id
    api
      .get("/v1/BookingController/all?id_user=" + id_user + "&limit=0&offset=20")
      .then((r) => {
        this.booking = r.data;
        this.loading = false;
      });
  },
};
</script>

<style scoped lang="scss">
.history {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    ul {
      list-style-type: none;
      padding: 0;

      li {
        padding: 15px;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;

        &:active {
          background: #eee;
        }

        b {
          text-transform: uppercase;
        }

        > div:nth-child(2) {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
          width: 150px;

          span {
            font-size: 12px;
            background: #82cc70;
            color: #fff;
            padding: 3px 8px 4px;
            border-radius: 4px;

            &.belum-bayar {
              background: rgb(240, 139, 45);
            }
          }

          b {
            display: block;
            font-size: 14px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
