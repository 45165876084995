<template>
  <div class="login">
    <div>
      <p style="text-align: center; margin: 0; padding-top: 30px;margin-bottom: 30px;">
        <img src="https://pra.pspp-integrated.com/assets/img/logo.png" width="130" />
      </p>
      <h2>
        Aplikasi Siswa <br />
        PSPP Penerbangan
      </h2>

      <form @submit.prevent="login">
        <div class="form-group">
          <label>NO DAFTAR</label>
          <input type="number" v-model="param.no_daftar" />
        </div>
        <div class="form-group">
          <label>PASSWORD</label>
          <input type="password" v-model="param.password" />
        </div>
        <div>
          <button type="submit" :disabled="loading" :class="{ disabled: loading }">
            Login
          </button>
        </div>
      </form>
    </div>

    <div class="modal" v-if="failed">
      <div class="modal-body fade-in">
        <p>PSPP Penerbangan</p>
        <h3>Login Gagal</h3>
        <button type="button" class="btn" @click="closeModal()">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      param: {
        no_daftar: "",
        password: "",
      },
      loading: false,
      failed: false,
    };
  },
  methods: {
    closeModal() {
      setTimeout(() => {
        this.failed = false;
      }, 150)
    },
    login() {
      this.loading = true;
      this.failed = false;
      axios
        .post(
          "https://api.pspp-integrated.com/api/v1/siswa/LoginController/validate",
          this.param
        )
        .then((r) => {
          this.loading = false;
          if (r.data.status == 1) {
            this.$store.commit("settings/setUser", r.data.data);
            this.failed = false;

            location.href = '../'
          } else {
            this.failed = true;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  background: #fff;
  height: 70vh;
  display: flex;
  align-items: center;

  >div {
    width: 100%;
  }

  h2 {
    text-align: center;
    line-height: 36px;
    margin: 30px 0px;
  }

  form {
    background: #fff;
    margin: 30px;
    padding: 20px;
    border-radius: 6px;

    .form-group {
      margin-bottom: 15px;

      label {
        display: block;
      }

      input {
        padding: 10px;
        width: calc(100% - 22px);
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;

        &:focus {
          border: 1px solid var(--primary);
        }
      }
    }

    button {
      background: var(--primary);
      width: 100%;
      padding: 15px;
      outline: none;
      border: 0;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 4px;

      &.disabled {
        background: var(--primary-lighter);
      }
    }
  }
}

.modal-body {
  padding: 20px;

  p {
    margin-top: 0;
  }
}
</style>
