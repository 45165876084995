<template>
  <div class="pt">
    <div class="navbar">
      <div @click="$router.back(0)">
        <back />
      </div>
      <div>Praktek Terbang</div>
    </div>

    <div class="body">

      <div class="tab">
        <span :class="{ active: tab == 'passport' }" @click="tab = 'passport'">Passport</span>
        <span :class="{ active: tab == 'pendaftaran' }" @click="tab = 'pendaftaran'">Pendaftaran</span>
      </div>

      <div class="tab-body">
        <div v-show="tab == 'passport'">
          <form @submit.prevent="updatePassport()">
            <div class="form-group">
              <label>No Passport</label>
              <input type="text" v-model="passport.no_paspor" v-uppercase>
            </div>
            <div class="form-group">
              <label>Nama Lengkap (Sesuai Passport)</label>
              <input type="text" v-model="passport.nama_paspor" v-uppercase>
            </div>
            <div class="form-group">
              <label for="">Jenis Kelamin</label>
              <div class="jk">
                <div>
                  <label for="l">
                    <input type="radio" v-model="passport.jenis_kelamin" value="L/M" id="l">
                    Laki-laki
                  </label>
                </div>
                <div>
                  <label for="p">
                    <input type="radio" v-model="passport.jenis_kelamin" value="P/F" id="p">
                    Perempuan
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Tempat Lahir</label>
              <input type="text" v-model="passport.tempat_lahir" v-uppercase>
            </div>
            <div class="form-group">
              <label>Tgl Lahir</label>
              <div class="tgl-group">
                <input type="number" v-model="passport.tgl_lahir" placeholder="Tgl">
                <select v-model="passport.bln_lahir">
                  <option v-for="item, index in bulan" :key="index" :value="index">{{ item }}</option>
                </select>
                <input type="number" v-model="passport.thn_lahir" placeholder="Tahun">
              </div>
            </div>
            <div class="form-group">
              <label>Tgl Expired</label>
              <div class="tgl-group">
                <input type="number" v-model="passport.tgl_expired" placeholder="Tgl">
                <select v-model="passport.bln_expired">
                  <option v-for="item, index in bulan" :key="index" :value="index">{{ item }}</option>
                </select>
                <input type="number" v-model="passport.thn_expired" placeholder="Tahun">
              </div>
            </div>
            <div class="form-group">
              <div class="file-control" :class="{ exist: passportFile }">
                <img :src="passportFile" width="100%" style="border-radius: 10px;" />

                <div class="file">
                  <input type="file" @change="previewPassport" accept="image/jpeg">
                  <span>
                    Foto Passport
                  </span>
                  <img src="https://img.icons8.com/?size=24&id=NjujHWc6iSDE&format=png">
                </div>
              </div>

            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" :disabled="loadingPassport">
                {{ loadingPassport ? 'LOADING...' : 'Update' }}
              </button>
            </div>
          </form>
        </div>
        <div v-show="tab == 'pendaftaran'">
          <div class="ketentuan" v-if="status == 'belum_lunas'">
            <div v-html="respon" class="notif"></div>
            <p style="margin-top: 30px;">
              <b>Ketentuan Mengikuti Praktek Terbang</b>
            </p>
            <div style="font-size: 14px; line-height: 22px">
              <div v-html="ketentuan"></div>
            </div>
          </div>

          <div class="ketentuan" v-if="status == 'dibuka'">

            <h3>Ketentuan Mengikuti Praktek Terbang</h3>
            <div style="font-size: 14px; line-height: 22px">
              <div v-html="ketentuan"></div>
            </div>

            <div class="notif" style="margin-bottom: 10px;" v-show="!sudahInputPassport">
              Silakan update passport terlebih dahulu
            </div>
            <button type="button" class="btn-primary" @click="status = 'daftar'" :disabled="!sudahInputPassport">
              Lanjutkan ke Pendaftaran
            </button>
          </div>

          <div class="form" v-if="status == 'daftar'">
            <p class="notif">
              Silakan isi formulir berikut untuk pendaftaran Praktek Terbang
            </p>
            <form @submit.prevent="daftar">
              <div class="form-group">
                <label for="">No Daftar</label>
                <input type="text" v-model="siswa.no_daftar" disabled />
              </div>
              <div class="form-group">
                <label for="">Nama Lengkap</label>
                <input type="text" v-model="siswa.nama_lengkap" disabled />
              </div>
              <div class="form-group">
                <label for="">Kampus</label>
                <input type="text" v-model="siswa.kampus" disabled />
              </div>
              <div class="form-group">
                <label for="">Jurusan</label>
                <input type="text" v-model="siswa.jurusan" disabled />
              </div>
              <div class="form-group">
                <label for="">No KTP</label>
                <input type="number" v-model="siswa.no_ktp" />
              </div>

              <!-- <div class="form-group">
                <label for="">No Passport :</label>
                <input type="text" v-model="siswa.no_passport" required />
              </div>
              <div class="form-group">
                <label for="">Upload Passport</label>
                <input type="file" @change="setPassport" required accept="image/jpg" />
              </div> -->

              <!-- <div class="form-group">
                <label for="">Telah melakukan vaksin ke- :</label>
                <input type="number" v-model="siswa.vaksin_ke" required />
              </div> -->
              <!-- <div class="form-group">
                <label for="">Upload bukti sertifikat vaksin</label>
                <input type="file" @change="setSertifikat" required accept="image/jpg" />
              </div> -->

              <div class="form-group">
                <label for="">No HP Aktif</label>
                <input type="tel" v-model="siswa.no_hp_aktif" required />
              </div>

              <div class="form-group">
                <label for="">Nama Keluarga untuk kondisi darurat</label>
                <input type="text" v-model="siswa.kontak_darurat" required />
              </div>
              <div class="form-group">
                <label for="">Nomor HP Keluarga untuk kondisi darurat</label>
                <input type="tel" v-model="siswa.no_hp_darurat" required />
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-primary" :disabled="loadingForm">
                  DAFTAR PRAKTEK TERBANG
                </button>
              </div>
            </form>
          </div>

          <div class="sukses" v-if="status == 'sudah_daftar'">
            <div v-html="respon"></div>
            <hr />
            <p>Ketentuan Praktek Terbang</p>
            <div style="font-size: 14px; line-height: 22px">
              <div v-html="ketentuan"></div>
            </div>
          </div>

          <div class="sukses" v-if="status == 'ditutup'">
            <div v-html="respon"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "../api/Api";

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      show: "",
      status: "",
      ketentuan: "",
      respon: "",
      formData: null,
      siswa: {
        id: null,
        no_hp: null,
        kontak_darurat: null,
        no_hp_darurat: null,
      },
      hasil: {},
      loadingForm: false,

      passport: {},
      tab: 'passport',
      bulan: ['',
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ],

      passportFile: '',
      loadingPassport: false,
      sudahInputPassport: false,
    };
  },
  methods: {
    setSertifikat(e) {
      const [file] = e.target.files;
      this.formData.append("sertifikat_vaksin", file);
    },
    setPassport(e) {
      const [file] = e.target.files;
      this.formData.append("gambar_passport", file);
    },
    previewPassport(e) {
      const file = e.target.files[0];

      this.passport.file = file;
      this.passportFile = URL.createObjectURL(file);
    },

    async loadPassport() {
      const res = await api.get('/v1/siswa/PassportController/get?id_siswa=' + this.$user.id);
      if (res.valid) {
        this.sudahInputPassport = true;
        this.passport = res.data;

        const tglLahir = this.passport.tgl_lahir.split('-');

        this.passport.tgl_lahir = tglLahir[2];
        this.passport.bln_lahir = parseInt(tglLahir[1]);
        this.passport.thn_lahir = tglLahir[0];

        const tglExpired = this.passport.tgl_expired.split('-');
        this.passport.tgl_expired = tglExpired[2];
        this.passport.bln_expired = parseInt(tglExpired[1]);
        this.passport.thn_expired = tglExpired[0];

        if (this.passport.foto_paspor) {
          this.passportFile = this.passport.foto_paspor;
        }
      }
    },

    async updatePassport() {
      this.loadingPassport = true;

      if (this.passport.nama_paspor) {
        this.passport.nama_paspor = this.passport.nama_paspor.toUpperCase();
      }
      if (this.passport.tempat_lahir) {
        this.passport.tempat_lahir = this.passport.tempat_lahir.toUpperCase();
      }

      if (this.passport.file) {
        const formData = new FormData();
        formData.append('id_siswa', this.siswa.id)
        formData.append('foto_paspor', this.passport.file)

        const res = await api.rawUpload("https://api.psppcdn.com/api/v1/siswa/PassportController/upload", formData);
        if (res.valid) {
          this.passport.foto_paspor = res.url;
        }
      }

      this.passport.id_siswa = this.siswa.id;
      const res = await api.post('/v1/siswa/PassportController/update', this.passport);
      this.loadingPassport = false;

      if (res.valid) {
        alert('Passport berhasil diperbarui');
      }
    },

    daftar() {
      if (
        this.siswa.no_hp_aktif &&
        this.siswa.kontak_darurat &&
        this.siswa.no_hp_darurat
      ) {
        this.loadingForm = true;

        this.formData.append("id_siswa", this.siswa.id);
        this.formData.append("no_hp_aktif", this.siswa.no_hp_aktif);
        this.formData.append("no_ktp", this.siswa.no_ktp);
        // this.formData.append("vaksin_ke", this.siswa.vaksin_ke);
        this.formData.append("no_passport", this.siswa.no_passport);
        this.formData.append("kontak_darurat", this.siswa.kontak_darurat);
        this.formData.append("no_hp_darurat", this.siswa.no_hp_darurat);
        // this.formData.append("ikut_ojt", this.siswa.ikut_ojt);
        // this.formData.append("lolos_kidora", this.siswa.lolos_kidora);

        api
          .upload("/v1/siswa/PraktekTerbangController/daftar", this.formData)
          .then((r) => {
            this.loadingForm = false;

            if (r.valid) {
              this.respon = r.message;
              this.status = "sudah_daftar";
            }
          })
          .catch((e) => {
            alert("Server error");
            this.loadingForm = false;
          });
      }
    },
  },
  mounted() {
    this.formData = new FormData();

    // load passport
    this.loadPassport();

    api
      .get("/v1/siswa/PraktekTerbangController/get?id_siswa=" + this.$user.id)
      .then((r) => {
        this.status = r.status;
        this.respon = r.message;
        this.ketentuan = r.ketentuan;

        const user = this.$store.state.settings.user;
        api.get("/v1/siswa/ProfilController/get?id=" + user.id).then((r) => {
          this.siswa = r.data;
          this.siswa.no_hp_aktif = r.data.no_hp_siswa;

          if (r.data.no_hp_siswa.substring(0, 3) == "+62") {
            this.siswa.no_hp_aktif =
              "0" + r.data.no_hp_siswa.replaceAll("-", "").substring(3, 20);
          } else if (r.data.no_hp_siswa.substring(0, 2) == "62") {
            this.siswa.no_hp_aktif =
              "0" + r.data.no_hp_siswa.replaceAll("-", "").substring(2, 20);
          } else if (this.$user.no_hp.substring(0, 1) == "8") {
            this.siswa.no_hp_aktif =
              "0" + r.data.no_hp_siswa.replaceAll("-", "").substring(1, 20);
          }
        });
      });
  },
};
</script>

<style scoped lang="scss">
.pt {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .tab {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;

    span {
      padding: 10px 0;
      width: 100%;
      text-align: center;

      &.active {
        color: var(--primary);
        font-weight: bold;
        border-bottom: 2px solid var(--primary);
      }
    }
  }

  .tab-body {
    padding-top: 20px;
  }

  .body {
    background: #fff;
    padding: 15px;

    .ketentuan {
      h3 {
        margin: 0;
        font-size: 16px;
      }

      ul {
        font-size: 14px;
        padding-left: 20px;

        li {
          padding: 5px 0;
        }
      }
    }
  }

  .notif {
    font-size: 13px;
    background: rgba(255, 239, 93, 0.479);
    padding: 10px;
    border: 1px solid rgb(250, 227, 100);
    border-radius: 6px;
  }
}

.tgl-group {
  display: grid;
  gap: 10px;
  grid-template-columns: 80px 1fr 100px;
}

.file-control {
  position: relative;
  margin-top: 40px;

  &.exist {
    margin-top: 0;

    >img {
      height: 200px;
      object-fit: contain;
    }

    .file {
      border-radius: 5px;
      margin: 10px;
      width: calc(100% - 62px);
    }
  }
}

.jk {
  display: flex;
  gap: 15px;

  label {
    display: flex;
    align-items: center;
    gap: 10px;

    input {
      padding: 0;
      margin: 0;
      width: auto;
      width: 16px;
      height: 16px;
    }
  }
}

.file {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px dashed #999;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  width: calc(100% - 42px);
  background: #fff;

  &:active {
    background: #eee;
  }

  img {
    height: 24px;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
