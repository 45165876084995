import axios from "axios";

const baseUrl = "https://api.pspp-integrated.com/api";
const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const get = url => {
  return axios.get(baseUrl + url).then(r => {
    return r.data;
  });
};

export const post = (url, data) => {
  const params = new URLSearchParams();
  Object.keys(data).forEach(item => {
    params.append(camelToSnakeCase(item), data[item]);
  });

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };

  return axios.post(baseUrl + url, params, config).then(r => {
    return r.data;
  });
};

export const upload = (url, data) => {
  return axios.post(baseUrl + url, data).then(r => {
    return r.data;
  });
};

export const rawUpload = (url, formData) => {
  return axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(r => {
      return r.data;
    });
};

export const postJson = (url, data) => {
  return axios.post(baseUrl + url, data).then(r => {
    return r.data;
  });
};

export const rawPost = (url, data) => {
  const params = new URLSearchParams();
  Object.keys(data).forEach(item => {
    params.append(camelToSnakeCase(item), data[item]);
  });

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };

  return axios.post(url, params, config).then(r => {
    return r.data;
  });
};