<template>
  <div class="page">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Data Siswa {{ convertStatus($route.params.status) }}</div>
    </div>

    <div class="body">

      <div class="card">
        <div class="card-body">
          <p>Filter data</p>
          <form class="grid" @submit.prevent="load()">
            <div class="form-group">
              <input type="text" placeholder="Cari siswa..." v-model="filter.nama">
            </div>
            <div class="form-group">
              <select v-model="filter.angkatan">
                <option value="-1">Semua angkatan</option>
                <option v-for="item, index in dataAngkatan" :key="index" :value="item">{{ item }}</option>
              </select>
            </div>
          </form>
          <button type="button" @click="load()" class="btn btn-primary" :disabled="loadingFilter">Filter</button>
        </div>
      </div>

      <ul class="pendaftar">
        <li v-for="item, index in data" :key="index" @click="to('/siswa/' + item.id)">
          <div>
            <p>
              <b>{{ item.nama_lengkap.toUpperCase() }}</b>
            </p>
            <div class="flex">
              <small>{{ item.kampus }}/{{ item.kode_jurusan }}/{{ item.angkatan_2 }}</small>
              <span>{{ item.no_hp_siswa }}</span>
            </div>
          </div>
          <div>
            <small>
              {{ moment(item.created_at).format('DD/MM/YY HH:mm') }}
            </small>
          </div>
        </li>
      </ul>
      <div class="card" v-if="data.length <= 0">
        <div class="card-body">
          <p class="mt">Tidak ada data</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Back from "./icons/Back.vue";
import { get } from '@/api/Api'

export default {
  components: {
    Back,
  },
  data() {
    return {
      loadingFilter: false,

      data: [],
      dataAngkatan: [],

      filter: {
        id_siswa_ref: this.$user.id,
        status: this.$route.params.status,
        angkatan: -1,
        nama: ''
      }
    }
  },
  methods: {
    moment,
    to(url) {
      setTimeout(() => {
        this.$router.push(url)
      }, 150)
    },
    convertStatus(status) {
      const statuses = {
        semua: 'Semua',
        baru: 'Baru Daftar',
        validasi: 'Sudah Divalidasi',
        ujian: 'Sudah Ujian',
        diterima: 'Diterima',
        ditolak: 'Tidak Diterima',
      }

      return statuses[status];
    },
    async load() {
      const filter = { ...this.filter };

      if (this.filter.angkatan == -1) {
        delete filter.angkatan;
      }
      if (this.filter.nama == '') {
        delete filter.nama;
      }

      this.loadingFilter = true;

      const queryString = Object.keys(filter)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filter[key]))
        .join('&');

      const res = await get('/v1/marketing/siswa/get?' + queryString)
      this.data = res.data.data;

      const r = await get('/v1/marketing/siswa/getAngkatan');
      this.dataAngkatan = r.data.slice(9);

      this.loadingFilter = false;
    }
  },
  mounted() {
    this.load();
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background: #fff;
  color: #333;
  padding: 15px;
  font-weight: 600;
  display: grid;
  grid-template-columns: 40px 1fr;
  border-bottom: 1px solid #ccc;
}

.body {
  margin: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 20px;

  .card-body {
    padding: 15px;

    p {
      margin: 0 0 10px;
    }

    button {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .form-group {
    margin-bottom: 0;
  }
}

.pendaftar {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:active {
      background: #eee;
    }

    p {
      margin: 0 0 10px;
    }

    .flex {
      span {
        display: flex;
        border: 1px solid var(--orange);
        font-size: 12px;
        padding: 4px;
        width: fit-content;
        color: var(--orange);
        border-radius: 4px;
        margin-top: 5px;
      }
    }
  }
}

.mt {
  padding: 20px 0;
  text-align: center;
}
</style>