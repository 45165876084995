<template>
  <div class="pemberkasan">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Pemberkasan</div>
    </div>

    <div class="body">
      <div class="card">
        <div class="card-title">Update Kontak</div>
        <div class="card-body">
          <p class="notif" v-if="!sudahUpdate">
            Silakan perbarui informasi berikut ini, klik Simpan jika sudah
            sesuai
          </p>
          <form @submit.prevent="updateKontak" autocomplete="off">
            <table class="table-form">
              <tr>
                <td valign="top">Email</td>
                <td>
                  <input
                    type="email"
                    v-model="user.email"
                    :class="submit && !user.email ? 'invalid' : ''"
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">No WA Aktif</td>
                <td>
                  <input
                    type="tel"
                    v-model="user.no_hp_siswa"
                    :class="submit && !user.no_hp_siswa ? 'invalid' : ''"
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">No WA Orangtua</td>
                <td>
                  <input
                    type="tel"
                    v-model="user.no_hp_orangtua"
                    :class="submit && !user.no_hp_orangtua ? 'invalid' : ''"
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">Kontak Darurat</td>
                <td>
                  <input
                    type="tel"
                    v-model="user.no_hp_orangtua"
                    :class="submit && !user.no_hp_orangtua ? 'invalid' : ''"
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">Alamat Sekarang</td>
                <td>
                  <textarea
                    v-model="user.alamat_sekarang"
                    :class="submit && !user.alamat_sekarang ? 'invalid' : ''"
                    autocomplete="off"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <button
                    type="submit"
                    class="btn"
                    :class="{ loading: submit && !invalid }"
                    v-if="!sudahUpdate"
                  >
                    Simpan
                  </button>
                </td>
              </tr>
            </table>
          </form>
        </div>
      </div>

      <div class="card">
        <div class="card-title">Silakan upload berkas yang diperlukan</div>
        <div class="card-body">
          <div class="verified" v-if="isVerified">
            <div>
              <img src="/static/icons/checked.svg" width="30" />
            </div>
            <div>Dokumen sudah divalidasi.</div>
          </div>

          <form :class="{ 'is-verified': isVerified }">
            <div class="form-wrapper">
              <div class="form-group">
                <div
                  class="gambar"
                  v-show="file.surat_seleksi.includes('http')"
                >
                  <div
                    class="bg-gambar"
                    :style="
                      'background-image: url(' + file.surat_seleksi + ');'
                    "
                  ></div>
                  <span>Surat Hasil Seleksi</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.surat_seleksi = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-show="!file.surat_seleksi.includes('http')">
                  <input
                    type="file"
                    accept="image/jpg"
                    name="surat_seleksi"
                    data-title="Surat Hasil Seleksi"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.surat_seleksi == ''"
                    />
                  </p>
                  {{
                    file.surat_seleksi
                      ? file.surat_seleksi
                      : "Surat Hasil Seleksi"
                  }}

                  <div class="loading" v-if="file.surat_seleksi">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div
                  class="gambar"
                  v-show="file.bukti_transfer.includes('http')"
                >
                  <div
                    class="bg-gambar"
                    :style="
                      'background-image: url(' + file.bukti_transfer + ');'
                    "
                  ></div>
                  <span>Bukti Transfer</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.bukti_transfer = ''"
                  >
                    <close />
                  </div>
                </div>
                <div
                  class="file"
                  v-show="!file.bukti_transfer.includes('http')"
                >
                  <input
                    type="file"
                    accept="image/jpg"
                    name="bukti_transfer"
                    data-title="Bukti Transfer"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.bukti_transfer == ''"
                    />
                  </p>
                  {{
                    file.bukti_transfer ? file.bukti_transfer : "Bukti Transfer"
                  }}

                  <div class="loading" v-if="file.bukti_transfer">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="gambar" v-show="file.pas_foto.includes('http')">
                  <div
                    class="bg-gambar"
                    :style="'background-image: url(' + file.pas_foto + ');'"
                  ></div>
                  <span>Pas Foto 3x4 Berwarna</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.pas_foto = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-show="!file.pas_foto.includes('http')">
                  <input
                    type="file"
                    accept="image/jpg"
                    name="pas_foto"
                    data-title="Pas Foto 3x4 Berwarna"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.pas_foto == ''"
                    />
                  </p>
                  {{ file.pas_foto ? file.pas_foto : "Pas Foto 3x4" }}

                  <div class="loading" v-if="file.pas_foto">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div
                  class="gambar"
                  v-show="file.kartu_identitas.includes('http')"
                >
                  <div
                    class="bg-gambar"
                    :style="
                      'background-image: url(' + file.kartu_identitas + ');'
                    "
                  ></div>
                  <span>KTP</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.kartu_identitas = ''"
                  >
                    <close />
                  </div>
                </div>
                <div
                  class="file"
                  v-show="!file.kartu_identitas.includes('http')"
                >
                  <input
                    type="file"
                    accept="image/jpg"
                    name="kartu_identitas"
                    data-title="KTP"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.kartu_identitas == ''"
                    />
                  </p>
                  {{ file.kartu_identitas ? file.kartu_identitas : "KTP" }}

                  <div class="loading" v-if="file.kartu_identitas">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="gambar" v-show="file.ktp_orangtua.includes('http')">
                  <div
                    class="bg-gambar"
                    :style="'background-image: url(' + file.ktp_orangtua + ');'"
                  ></div>
                  <span>KTP Orangtua</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.ktp_orangtua = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-show="!file.ktp_orangtua.includes('http')">
                  <input
                    type="file"
                    accept="image/jpg"
                    name="ktp_orangtua"
                    data-title="KTP Orangtua"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.ktp_orangtua == ''"
                    />
                  </p>
                  {{ file.ktp_orangtua ? file.ktp_orangtua : "KTP Orangtua" }}

                  <div class="loading" v-if="file.ktp_orangtua">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div
                  class="gambar"
                  v-show="file.ijin_orangtua.includes('http')"
                >
                  <div
                    class="bg-gambar"
                    :style="
                      'background-image: url(' + file.ijin_orangtua + ');'
                    "
                  ></div>
                  <span>Ijin Orangtua</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.ijin_orangtua = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-show="!file.ijin_orangtua.includes('http')">
                  <input
                    type="file"
                    accept="image/jpg"
                    name="ijin_orangtua"
                    data-title="Ijin Orangtua"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.ijin_orangtua == ''"
                    />
                  </p>
                  {{
                    file.ijin_orangtua ? file.ijin_orangtua : "Ijin Orangtua"
                  }}

                  <div class="loading" v-if="file.ijin_orangtua">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="gambar" v-show="file.skck.includes('http')">
                  <div
                    class="bg-gambar"
                    :style="'background-image: url(' + file.skck + ');'"
                  ></div>
                  <span>SKCK</span>
                  <div class="ganti" v-if="!isVerified" @click="file.skck = ''">
                    <close />
                  </div>
                </div>
                <div class="file" v-show="!file.skck.includes('http')">
                  <input
                    type="file"
                    accept="image/jpg"
                    name="skck"
                    data-title="SKCK"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.skck == ''"
                    />
                  </p>
                  {{ file.skck ? file.skck : "SKCK" }}

                  <div class="loading" v-if="file.skck">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="gambar" v-show="file.hasil_rapid.includes('http')">
                  <div
                    class="bg-gambar"
                    :style="'background-image: url(' + file.hasil_rapid + ');'"
                  ></div>
                  <span>Hasil Rapid / PCR</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.hasil_rapid = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-show="!file.hasil_rapid.includes('http')">
                  <input
                    type="file"
                    accept="image/jpg"
                    name="hasil_rapid"
                    data-title="Hasil Rapid / PCR"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.hasil_rapid == ''"
                    />
                  </p>
                  {{
                    file.hasil_rapid ? file.hasil_rapid : "Hasil Rapid / PCR"
                  }}

                  <div class="loading" v-if="file.hasil_rapid">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div
                  class="gambar"
                  v-if="file.sertifikat_vaksin.includes('http')"
                >
                  <div
                    class="bg-gambar"
                    :style="
                      'background-image: url(' + file.sertifikat_vaksin + ');'
                    "
                  ></div>
                  <span>Sertifikat Vaksin</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.sertifikat_vaksin = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-else>
                  <input
                    type="file"
                    accept="image/jpg"
                    name="sertifikat_vaksin"
                    data-title="Sertifikat Vaksin"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.sertifikat_vaksin == ''"
                    />
                  </p>
                  {{
                    file.sertifikat_vaksin
                      ? file.sertifikat_vaksin
                      : "Sertifikat Vaksin"
                  }}

                  <div class="loading" v-if="file.sertifikat_vaksin">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div
                  class="gambar"
                  v-if="file.surat_tidak_dijamin.includes('http')"
                >
                  <div
                    class="bg-gambar"
                    :style="
                      'background-image: url(' + file.surat_tidak_dijamin + ');'
                    "
                  ></div>
                  <span>Surat tidak diijamin kerja</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.surat_tidak_dijamin = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-else>
                  <input
                    type="file"
                    accept="image/jpg"
                    name="surat_tidak_dijamin"
                    data-title="Surat tidak dijamin kerja"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.surat_tidak_dijamin == ''"
                    />
                  </p>
                  {{
                    file.surat_tidak_dijamin
                      ? file.surat_tidak_dijamin
                      : "Surat tidak dijamin kerja"
                  }}

                  <div class="loading" v-if="file.surat_tidak_dijamin">
                    <spinner />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div
                  class="gambar"
                  v-if="file.pantauwal.includes('http')"
                >
                  <div
                    class="bg-gambar"
                    :style="
                      'background-image: url(' + file.pantauwal + ');'
                    "
                  ></div>
                  <span>Pantauwal</span>
                  <div
                    class="ganti"
                    v-if="!isVerified"
                    @click="file.pantauwal = ''"
                  >
                    <close />
                  </div>
                </div>
                <div class="file" v-else>
                  <input
                    type="file"
                    accept="image/jpg"
                    name="pantauwal"
                    data-title="Pantauwal"
                    :disabled="isVerified"
                  />
                  <p style="height: 35px">
                    <img
                      src="/static/icons/file.svg"
                      height="30"
                      v-show="file.pantauwal == ''"
                    />
                  </p>
                  {{
                    file.pantauwal
                      ? file.pantauwal
                      : "Pantauwal"
                  }}

                  <div class="loading" v-if="file.pantauwal">
                    <spinner />
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal" v-if="modalOpen">
      <div class="modal-header">{{ modalTitle }}</div>
      <div class="modal-body">
        <p class="notif">Pastikan file yang dipilih sudah sesuai</p>
        <p style="text-align: center; padding: 5px">
          <img
            :src="selectedFileSrc"
            style="
              box-shadow: 0px 1px 6px #333;
              max-width: 100%;
              max-height: 50vh;
            "
          />
        </p>
        <div class="btn-wrapper">
          <button type="button" class="btn-2nd" @click="modalOpen = false">
            GANTI
          </button>
          <button
            type="button"
            class="btn"
            @click="upload(selectedName, selectedFile)"
          >
            UPLOAD
          </button>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="modalOpen"></div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Spinner from "./icons/Spinner.vue";
import Close from "./icons/Close.vue";

import axios from "axios";
import * as api from "../api/Api";

export default {
  name: "Tiket",
  components: {
    Back,
    Spinner,
    Close,
  },
  data() {
    return {
      modalOpen: false,
      modalTitle: false,
      file: {
        surat_seleksi: "",
        bukti_transfer: "",
        pas_foto: "",
        kartu_identitas: "",
        akta_kelahiran: "",
        ktp_orangtua: "",
        kartu_keluarga: "",
        ijin_orangtua: "",
        skck: "",
        hasil_rapid: "",
        sertifikat_vaksin: "",
        surat_tidak_dijamin: "",
        pantauwal: ""
      },
      selectedFile: null,
      selectedFileSrc: null,
      selectedName: null,
      isVerified: false,
      submit: false,
      invalid: false,
      user: {},
      sudahUpdate: true,
    };
  },
  methods: {
    upload(field, file) {
      const self = this;

      const data = new FormData();
      data.append(field, file);

      const config = {
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          self.file[field] = percentCompleted + "%";

          if (percentCompleted >= 100) {
            self.file[field] = "Menyelesaikan";
          }
        },
      };

      axios
        .post(
          "https://api.pspp-integrated.com/api/v1/SiswaController/uploadPemberkasan?doc=" +
            field +
            "&id_siswa=" +
            this.$store.state.settings.user.id,
          data,
          config
        )
        .then(function (res) {
          self.file[res.data.field] = "DONE";
          if (res.data.valid) {
            setTimeout(function () {
              self.file[res.data.field] = res.data.url;
            }, 1000);
          }
        })
        .catch(function (err) {
          console.log(err.message);
        });

      this.modalOpen = false;
    },
    checkValidasi() {
      axios
        .get(
          "https://api.pspp-integrated.com/api/v1/SiswaController/validation_check?id_siswa=" +
            this.$store.state.settings.user.id
        )
        .then((r) => {
          this.isVerified = r.data.valid;
          this.sudahUpdate = r.data.sudah_update;
          this.user = r.data.siswa;
        });
    },
    getFile(name) {
      axios
        .get(
          "https://api.pspp-integrated.com/api/v1/SiswaController/file_check?id_siswa=" +
            this.$store.state.settings.user.id +
            "&doc=" +
            name
        )
        .then((r) => {
          if (r.data.valid) {
            this.file[name] = r.data.url;
          }
        });
    },
    updateKontak() {
      this.submit = true;
      this.invalid = false;

      if (this.submit) {
        if (
          !this.user.email ||
          !this.user.no_hp_siswa ||
          !this.user.alamat_sekarang
        ) {
          this.invalid = true;
          return;
        }
      }

      this.user.id_siswa = this.$store.state.settings.user.id;

      api
        .post("/v1/SiswaController/update_kontak", this.user)
        .then((r) => {
          alert("Data berhasil diperbarui");

          this.submit = false;
          this.sudahUpdate = true;
        })
        .catch(function (err) {
          this.submit = false;
        });
    },
  },
  mounted() {
    const user = {
      email: this.$store.state.settings.user.email,
      no_hp_siswa: this.$store.state.settings.user.no_hp,
      alamat_sekarang: null,
    };

    this.user = user;

    this.checkValidasi();

    this.getFile("surat_seleksi");
    this.getFile("bukti_transfer");
    this.getFile("pas_foto");
    this.getFile("kartu_identitas");
    this.getFile("akta_kelahiran");
    this.getFile("ktp_orangtua");
    this.getFile("kartu_keluarga");
    this.getFile("ijin_orangtua");
    this.getFile("skck");
    this.getFile("hasil_rapid");
    this.getFile("sertifikat_vaksin");
    this.getFile("surat_tidak_dijamin");
    this.getFile("pantauwal");

    document.querySelectorAll('[type="file"]').forEach((elm) => {
      elm.addEventListener("change", (e) => {
        const [file] = e.target.files;
        console.log(file);
        if (file) {
          this.selectedFileSrc = URL.createObjectURL(file);
          this.selectedFile = file;
          this.selectedName = e.target.name;
          this.modalTitle = e.target.dataset.title;
          this.modalOpen = true;
        }
      });
    });
  },
};
</script>

<style scoped lang="scss">
.pemberkasan {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }
  .body {
    background: #f7f7f7;
    padding: 15px;

    .verified {
      display: grid;
      grid-template-columns: 45px 1fr;
      padding: 15px;
      border: 1px solid #32ba7c;
      margin-bottom: 15px;
      background: #32ba7c;
      border-radius: 6px;
      color: #fff;

      > div {
        display: flex;
        align-items: center;
      }
    }

    .card {
      background: #fff;
      border-radius: 6px;
      padding: 15px;
      box-shadow: 0px 1px 3px #aaa;
      margin-bottom: 20px;

      .card-title {
        border-bottom: 1px solid #ccc;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }
    }

    form {
      .form-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        margin-bottom: 15px;
      }

      .form-group {
        .file {
          border: 1px dashed #ccc;
          border-radius: 6px;
          position: relative;
          padding: 15px;
          font-size: 14px;
          text-align: center;

          input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        .gambar {
          position: relative;
          .ganti {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            background: rgba(255, 255, 255, 0.8);
            height: 20px;
          }
          .bg-gambar {
            height: 90px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
          span {
            display: block;
            text-align: center;
            font-size: 14px;
            margin-top: 5px;
          }
        }

        .loading {
          position: absolute;
          top: 15px;
          left: 55px;
        }
      }

      &.is-verified {
        p {
          opacity: 0.2;
        }
      }
    }
  }

  .btn {
    background-color: var(--primary);
    color: #fff;
    padding: 15px;
    outline: none;
    border: 0;
    width: 100%;
    display: block;
    border-radius: 50px;
    font-weight: 600;
    text-transform: uppercase;
    &:active {
      background-color: #12a2ca;
    }
    &.loading {
      background-color: #a0eaff;
    }
  }

  .btn-2nd {
    background-color: #fff;
    color: #f96d01;
    padding: 15px;
    outline: none;
    border: 1px solid #f96d01;
    width: 100%;
    display: block;
    border-radius: 50px;
    font-weight: 600;

    &:active {
      background-color: #eee;
    }
  }

  .modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 9;

    .modal-header {
      border-bottom: 1px solid #ccc;
      padding: 15px;
      font-size: 14px;
      font-weight: 600;
    }

    .modal-body {
      margin: 15px;

      .btn-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
      }
    }
  }

  .table-form {
    width: 100%;
    border-collapse: collapse;

    td:first-child {
      width: 140px;
      font-size: 14px;
    }

    input,
    textarea {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      width: calc(100% - 12px);
      outline: none;
      margin-bottom: 5px;
      &:focus {
        border: 1px solid var(--primary);
      }

      &.invalid {
        border: 1px solid #f00;
      }
    }
  }

  p.notif {
    font-size: 13px;
    background: rgba(255, 239, 93, 0.479);
    padding: 10px;
    border: 1px solid rgb(250, 227, 100);
    border-radius: 6px;
  }

  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
