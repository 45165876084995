<template>
  <div class="history-detail">
    <div class="navbar">
      <div @click="$router.push('/history')">
        <back />
      </div>
      <div>Detail Booking</div>
    </div>

    <div class="body">
      <div v-if="loading">
        <p style="text-align: center">
          <loading />
        </p>
      </div>
      <div v-else>
        <div class="group">
          <p class="title">Penerbangan</p>

          <div class="kode-booking" v-if="booking.booking.status_issued == 1">
            <p>Kode Booking</p>
            <h1>{{ booking.booking.kode_booking }}</h1>
          </div>

          <span v-if="booking.booking.status_bayar == 1" class="lunas">
            LUNAS
          </span>

          <table class="">
            <tr>
              <td>Dari</td>
              <td>:</td>
              <td>{{ booking.booking.kota_asal }}</td>
            </tr>
            <tr>
              <td>Tujuan</td>
              <td>:</td>
              <td>{{ booking.booking.kota_tujuan }}</td>
            </tr>
            <tr>
              <td style="padding-right: 10px">Tgl Terbang</td>
              <td style="padding-right: 6px">:</td>
              <td>
                {{ moment(booking.booking.tgl_terbang).format("DD MMM YYYY") }}
              </td>
            </tr>
          </table>

          <p>
            <img
              :src="
                'https://apitama.xyz/flight-api/sampel_api_tiket_pesawat/images/' +
                $validateMaskapai(
                  booking.booking_flight[0].flight_number,
                  booking.booking.kode_airline
                ) +
                '.png'
              "
              width="80"
            />
          </p>
          <ul class="detail-penerbangan">
            <li v-for="(item, index) in booking.booking_flight" :key="index">
              <p>
                {{
                  $validateMaskapai(item.flight_number, booking.booking.airline)
                }}
                ({{ item.flight_number }})
              </p>
              <table>
                <tr>
                  <td style="width: 80px">{{ item.bandara_asal }}</td>
                  <td>
                    {{
                      moment(
                        booking.booking.tgl_terbang + " " + item.jam_berangkat
                      ).format("HH:mm")
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ item.bandara_tujuan }}</td>
                  <td>
                    {{
                      moment(
                        booking.booking.tgl_terbang + " " + item.jam_sampai
                      ).format("HH:mm")
                    }}
                  </td>
                </tr>
              </table>
            </li>
          </ul>

          <a
            v-if="booking.booking.status_issued == 1"
            :href="
              'https://apitama.xyz/flight-api/index.php/api/v1/BookingController/eTiket/' +
              booking.booking.id
            "
            class="btn-download"
          >
            Download E-Tiket
          </a>
        </div>

        <div class="group">
          <p class="title">Penumpang</p>
          <div class="penumpang">
            <div v-if="booking.booking_passenger">
              <ul>
                <li
                  v-for="(item, index) in booking.booking_passenger"
                  :key="index"
                >
                  {{ item.nama }} ({{ item.kategori }}) <br />
                  <span style="font-weight: 500; text-transform: initial"
                    >Rp {{ item.total | number }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="group" style="position: relative">
          <p class="title">Total Pembayaran</p>
          <span class="belum-lunas" v-if="booking.booking.status_bayar != 1"
            >Belum Lunas</span
          >
          <table width="100%">
            <tr>
              <td>Subtotal</td>
              <td align="right">{{ booking.booking.subtotal | number }}</td>
            </tr>
            <tr>
              <td style="padding-right: 10px">Biaya Admin</td>
              <td align="right">{{ booking.booking.admin | number }}</td>
            </tr>
          </table>
          <hr />
          <span style="position: absolute; margin-top: 15px"> Total </span>
          <h1 style="text-align: right">
            Rp {{ booking.booking.grand_total | number }}
          </h1>
        </div>

        <div
          class="group"
          v-if="
            booking.booking.status_bayar != 1 &&
            booking.booking.status_issued != 1
          "
        >
          <p class="title">Pembayaran</p>
          <div class="pembayaran" v-if="!booking.booking.kode_pembayaran">
            <p style="font-weight: 400; margin-bottom: 20px">
              Silakan transfer ke rekening berikut:
            </p>

            <p>
              <b>BRI</b> <br />
              <b>123456789</b> <br />
              <b>PT. Kidora Mandiri Investama</b> <br />
            </p>

            <div
              v-show="booking.booking.bukti_pembayaran"
              style="
                margin-top: 15px;
                padding-top: 15px;
                border-top: 1px solid #ccc;
              "
            >
              <div class="form-group">
                <label style="display: block; margin-bottom: 10px"
                  >Bukti Pembayaran</label
                >
                <img :src="booking.booking.bukti_pembayaran" width="100%" />
              </div>
            </div>

            <div style="margin: 30px 0" v-show="showUpload">
              <form @submit.prevent="upload()">
                <div class="form-group">
                  <label>Pilih bukti pembayaran</label>
                  <input type="file" accept="image/jpg" id="bukti-pembayaran" />
                </div>
                <div class="form-group">
                  <button type="submit" class="btn" style="width: 100%" v-show="!loadingBayar">
                    Upload bukti pembayaran
                  </button>
                </div>
              </form>
            </div>

            <button
              @click="showUpload = true"
              v-if="!showUpload && !booking.booking.bukti_pembayaran"
            >
              KONFIRMASI PEMBAYARAN
            </button>
            <span v-if="error" style="color: #f00"
              >Terjadi kesalahan, silakan coba lagi</span
            >
            <p style="text-align: center" v-if="loadingBayar">
              <loading />
            </p>
          </div>

          <div v-if="booking.booking.kode_pembayaran">
            <h3 style="text-align: center">Bayar Sebelum :</h3>
            <h1 style="text-align: center">
              {{
                moment(booking.booking.batas_pembayaran).format(
                  "DD-MM-YY HH:mm"
                )
              }}
            </h1>

            <p
              style="text-align: center"
              v-if="booking.booking.tujuan_pembayaran == 'bca'"
            >
              <img src="/static/icons/bca.jpg" width="60%" />
            </p>
            <p
              style="text-align: center"
              v-if="booking.booking.tujuan_pembayaran == 'bni'"
            >
              <img src="/static/icons/bni.jpg" width="60%" />
            </p>
            <p
              style="text-align: center"
              v-if="booking.booking.tujuan_pembayaran == 'mandiri'"
            >
              <img src="/static/icons/mandiri.jpg" width="70%" />
            </p>

            <p style="text-align: center">
              Bayar dengan Virtual Account
              <span style="text-transform: uppercase">{{
                booking.booking.tujuan_pembayaran
              }}</span
              >, pembayaran akan dicek otomatis. <br />
              No Virtual Account :
            </p>
            <h1 style="text-align: center">
              {{ booking.booking.kode_pembayaran }}
            </h1>
            <p
              style="text-align: center; text-decoration: underline"
              @click="copyText"
            >
              Salin no VA
            </p>
            <input
              type="text"
              :value="booking.booking.kode_pembayaran"
              id="no-va"
              style="opacity: 0"
            />
          </div>
        </div>

        <div class="group" v-if="booking.booking.status_issued == 1">
          <p class="title">Syarat & Ketentuan Maskapai</p>
          <p>
            <img
              :src="
                'https://apitama.xyz/flight-api/sampel_api_tiket_pesawat/images/' +
                $validateMaskapai(
                  booking.booking_flight[0].flight_number,
                  booking.booking.kode_airline
                ) +
                '.png'
              "
              width="80"
            />
          </p>
          <div v-html="booking.sk_maskapai.keterangan"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import moment from "moment";
import axios from "axios";
import * as api from "../api/FlightApi";

export default {
  name: "Search",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loading: true,
      loadingBayar: false,
      error: false,
      booking: {},
      pembayaran: "",
      showUpload: false,
    };
  },
  methods: {
    moment,
    bayar() {
      const param = {
        id_booking: this.booking.booking.id,
        payment_type: "bank_transfer",
        transaction_details: {
          gross_amount: this.booking.booking.grand_total,
          order_id: this.booking.booking.id,
        },
        bank_transfer: {
          bank: this.pembayaran,
        },
      };

      this.loadingBayar = true;
      this.error = false;
      api
        .postJson("/v1/PaymentController/charge", param)
        .then((r) => {
          this.loadingBayar = false;
          location.reload();
        })
        .catch((e) => {
          this.error = true;
          this.loadingBayar = false;
        });
    },
    copyText() {
      const copyText = document.getElementById("no-va");

      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      document.execCommand("copy");

      alert("No VA disalin: " + copyText.value);
    },
    upload() {
      this.loadingBayar = true;

      const data = new FormData();
      data.append("id_booking", this.booking.booking.id);
      data.append(
        "bukti_pembayaran",
        document.getElementById("bukti-pembayaran").files[0]
      );

      axios
        .post(
          "https://apitama.xyz/flight-api/index.php/api/v1/BookingController/uploadBuktiPembayaran",
          data
        )
        .then((res) => {
          this.load();
          this.loadingBayar = false;
        })
        .catch(function (err) {
          console.log(err.message);
          this.loadingBayar = false;
        });
    },
    load() {
      api
        .get("/v1/BookingController/get?id_booking=" + this.$route.params.id)
        .then((r) => {
          this.booking = r;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped lang="scss">
.history-detail {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    background: #eee;

    table {
      border-collapse: collapse;
      td {
        padding: 5px 0px;
        font-size: 14px;
      }
    }

    .kode-booking {
      margin-top: 15px;
      margin-bottom: 15px;
      p,
      h1 {
        margin: 0;
      }
    }

    .group {
      background: #fff;
      padding: 15px;
      box-shadow: 0px 2px 3px #ccc;
      margin-bottom: 13px;
      p.title {
        margin: 0;
        color: var(--primary);
        font-weight: 500;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .detail-penerbangan {
      margin: 0;
      p {
        margin: 0;
        font-weight: 600;
        margin-bottom: 10px;
      }

      padding-left: 20px;
      li {
        font-size: 14px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .penumpang {
      font-size: 14px;
      p {
        margin: 0;
        font-weight: 500;
      }

      ul {
        display: block;
        padding-left: 20px;
        margin: 0;
        margin-bottom: 10px;

        li {
          text-transform: uppercase;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .tagihan {
      border: 1px solid var(--primary);
      border-radius: 6px;
      margin-top: 30px;
      padding: 15px;

      p,
      h1 {
        margin: 0;
      }
    }

    .pembayaran {
      p {
        margin: 0;
        font-weight: 600;
        margin-bottom: 10px;
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          label {
            display: block;
            padding: 10px 0px;
            border-bottom: 1px solid #ccc;
            &:active {
              background: #eee;
            }
          }
        }
      }
    }
  }

  button,
  .btn {
    background: var(--primary);
    color: #fff;
    padding: 15px;
    width: calc(100%);
    text-align: center;
    text-transform: uppercase;
    outline: none;
    border: 0;
    margin-top: 15px;
    border-radius: 6px;
    margin-bottom: 30px;
    font-weight: 600;
  }

  .btn {
    font-size: 14px;
    margin: 0 auto;
    width: 220px;
    text-decoration: none;
  }

  span.lunas {
    position: absolute;
    right: 15px;
    margin-top: -70px;
    font-size: 11px;
    padding: 4px 6px;
    background: #33c732;
    color: #fff;
    border-radius: 4px;
    font-weight: 600;
  }

  span.belum-lunas {
    font-size: 12px;
    color: #fff;
    padding: 3px 8px 4px;
    border-radius: 4px;
    background: rgb(240, 139, 45);
    position: absolute;
    right: 15px;
    margin-top: -30px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;

    .modal-body {
      margin: 15px;
    }
  }

  .btn-download {
    background: #33c732;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border: 0;
    border-radius: 6px;
    outline: none;
    padding: 10px;
    display: block;
    margin-top: 10px;
    font-weight: 500;
  }
}
</style>
