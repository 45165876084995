<template>
  <div class="page">
    <h2>Aktivasi Siswa Get Siswa</h2>
    <p>Mohon dibaca dengan seksama mengenai Syarat & Ketentuan di bawah ini</p>

    <h3>Syarat & Ketentuan Marketing Siswa Get Siswa</h3>
    <ul>
      <li>
        Marketing Siswa Get Siswa merupakan program pelatihan nyata dari mata diklat Digital marketing bagi
        siswa yang masih aktif di kampus PSPP Penerbangan
      </li>
      <li>
        Dari program ini diharapkan siswa mampu mengaplikasikan pemasaran dalam mencari calon siswa didik untuk
        belajar di kampus PSPP Penerbangan
      </li>
      <li>
        Keuntungan dari program ini, siswa akan mendapatkan tambahan penghasilan.
      </li>
      <li>
        Untuk dapat menjalankan program Marketing Siswa Get Siswa (SGS), siswa wajib mengisi data isian yang
        telah disediakan dalam Aplikasi Siswa PSPP Penerbangan.
      </li>
      <li>
        Setelah siswa melakukan pendaftaran Marketing Siswa Get Siswa, maka siswa tersebut telah dapat
        menjalankan program Pemasaran PSPP Penerbangan
      </li>
      <li>
        Masa berlaku Marketing Siswa Get Siswa adalah selama siswa tersebut masih menjadi siswa aktif PSPP
        Penerbangan
      </li>
    </ul>

    <label>
      <input type="checkbox" v-model="setuju">
      Saya telah membaca & setuju dengan syarat & ketentuan marketing Siswa Get Siswa
    </label>

    <button type="button" class="btn btn-primary" :disabled="!setuju" @click="aktivasi()">AKTIVASI</button>
  </div>
</template>

<script>
import * as api from "../api/Api";

export default {
  data() {
    return {
      setuju: false
    }
  },
  methods: {
    async aktivasi() {
      const param = {
        status_sgs: 1,
        ref: this.$route.query.ref,
      }
      const res = await api.post('/v1/marketing/siswa/aktivasi', param);
      if (res.valid) {
        alert('Aktivasi Berhasil');
        this.$router.push('/marketing');
      } else {
        alert('Aktivasi Gagal');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 30px;
}

h2 {
  margin-top: 0;
}

ul {
  padding: 0;
  margin: 0 0 20px 20px;
}

button {
  margin-top: 15px;
}
</style>