<template>
  <div class="history">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>History Booking Paket Wisata</div>
    </div>

    <div class="body">
      <div v-if="loading">
        <p style="text-align: center">
          <loading />
        </p>
      </div>
      <ul v-else>
        <li v-for="(item, index) in history" :key="index">
          <div>
            <p>
              <b>{{ item.nama }}</b>
            </p>
            <small>
              Tgl Berangkat : {{ moment(item.tgl_wisata).format('DD/MM/YYYY') }} <br/>
              Jumlah Pax : {{ item.jumlah_pax }} Pax
            </small>
          </div>
          <div>
            <span>
              {{ item.jumlah_pax * item.harga_jual | number }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "../api/Api";
import moment from "moment";

export default {
  name: "Search",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loading: true,
      history: [],
    };
  },
  methods: {
    moment,
    to(item) {
      setTimeout(() => {
        this.$router.push("/history/" + item.id);
      }, 150);
    },
  },
  mounted() {
    const id_user = this.$store.state.settings.user.id;
    api.get("/v1/abbetama/WisataController/history/" + id_user).then((r) => {
      this.history = r.data;
      this.loading = false;
      console.log(this.history);
    });
  },
};
</script>

<style scoped lang="scss">
.history {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    ul {
      list-style-type: none;
      padding: 0;

      li {
        padding: 15px;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
        }

        &:active {
          background: #eee;
        }

        b {
          text-transform: uppercase;
        }

        > div:nth-child(2) {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
          width: 150px;

          span {
            font-size: 12px;
            background: #82cc70;
            color: #fff;
            padding: 3px 8px 4px;
            border-radius: 4px;

            &.belum-bayar {
              background: rgb(240, 139, 45);
            }
          }

          b {
            display: block;
            font-size: 14px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
