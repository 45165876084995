<template>
  <div>

    <div class="navbar" v-if="!selected.id">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Angket</div>
    </div>
    <div class="angket">

      <h3 v-if="!selected.id">QUESTIONARE PENILAIAN SISWA TERHADAP INSTRUKTUR</h3>
      <div class="intro" v-if="!selected.id">
        <p>
          Apa kabar Gaesss...!!! <br />
          Tentunya perasaan lagi "hepi" dan semoga hasil nilainya juga baik ya Gaess untuk semua mata diklat nya...,
          aamiin
        </p>
        <p>
          Oh iya Gaess, masih ingat kan dengan Instruktur yang kemarin memberikan materi maupun praktek di kelas
          perkuliahan, secara online atau offline. <br />
          Yuk...!, beri penilaian kepada Bapak dan Ibu Instruktur dengan mengisi kuesioner ini. <br />
          Dengan penilaian dari kalian tentunya akan menjadi bahan evaluasi dan peningkatan pelayanan pembelajaran
          berikutnya akan menjadi lebih baik. <br />
        </p>
        <p>
          Tentunya isi kuesioner ini dengan lengkap, obyektif dan jujur ya Gaess. <br />
          Salam dari kami,. <br />
        </p>

        <p>
          Manajemen PSPP Penerbangan
        </p>
      </div>

      <div class="info" v-if="!selected.id">
        <p>
          <b>Petunjuk Pengisian</b>
        </p>
        <ul>
          <li>Pilih Mata Diklat di bawah ini</li>
          <li>Pilih salah satu dari pilihan ganda untuk menunjukkan penilaian :

            <ul>
              <li>Bintang 5 = Sangat baik</li>
              <li>Bintang 4 = Baik</li>
              <li>Bintang 3 = Cukup</li>
              <li>Bintang 2 = Buruk</li>
              <li>Bintang 1 = Sangat buruk</li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="mata-diklat" v-if="!selected.id">
        <p>
          <b>Daftar mata diklat</b>
        </p>
        <div style="display: flex;justify-content: center;" v-if="isLoading">
          <loading />
        </div>
        <ul v-if="!isLoading">
          <li v-for="item, index in dataMataDiklat" :key="index" @click="select(item)">
            <div>
              <p>
                {{ item.mata_diklat }}
              </p>
              <small>{{ item.instruktur }}</small>
            </div>
            <div>
              <span v-if="item.sudah" style="background: #ffd000;color: #333;">{{ item.nilai }}</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="form" v-if="selected.id">

        <div @click="kembali()" class="nav">
          <back /> Kembali
        </div>

        <h3>
          {{ selected.mata_diklat }}
        </h3>
        <p>{{ selected.instruktur }}</p>
        <br />
        <ol>
          <li v-for="form, index in data" :key="index">
            <p>{{ form.text }}</p>
            <div class="rating">
              <span v-for="n in 5" :key="n" @click="setRating(index, n)" @mouseover="hoverRating(index, n)"
                @mouseleave="hoverRating(index, 0)" :class="{ 'star-filled': n <= form.rating || n <= form.hover }"
                class="star">
                ★
              </span>
            </div>
          </li>
        </ol>
        <button type="button" class="btn-primary" @click="submit()" :disabled="isSaving">Submit</button>
      </div>
    </div>
  </div>

</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";
import * as api from "../api/Api";
import axios from 'axios';

export default {
  components: {
    Back, Loading
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,

      selected: {},
      data: [
        {
          text: 'Instruktur menguasai meteri yang diajarkan pada saat kuliah berlangsung',
          rating: 0,
          hover: 0
        },
        {
          text: 'Contoh-contoh yang diberikan Instruktur sesuai dengan materi kuliah',
          rating: 0,
          hover: 0
        },
        {
          text: 'Instruktur pada saat memberi kuliah dapat menjelaskan materi kuliah secara baik',
          rating: 0,
          hover: 0
        },
        {
          text: 'Instruktur memberikan tanggapan yang baik atas pertanyaan dari Siswa',
          rating: 0,
          hover: 0
        },
        {
          text: 'Instruktur menyajikan materi kuliah secara berurutan sesuai silabi',
          rating: 0,
          hover: 0
        },
        {
          text: 'Disiplin Instruktur terhadap ketepatan waktu kuliah',
          rating: 0,
          hover: 0
        },
        {
          text: 'Cara Instruktur mengajar dapat meningkatkan minat belajar Siswa',
          rating: 0,
          hover: 0
        },
        {
          text: 'Instruktur menguasai kelas dengan baik',
          rating: 0,
          hover: 0
        },
        {
          text: 'Instruktur mampu menggunakan waktu kuliah dengan efisien',
          rating: 0,
          hover: 0
        },
        {
          text: 'Instruktur menggunakan rujukan perkuliahan yang jelas',
          rating: 0,
          hover: 0
        },
      ],

      dataMataDiklat: [],
    }
  },
  methods: {
    select(item) {
      // reset nilai
      this.data.forEach((item) => {
        item.rating = 0;
      })

      setTimeout(() => {
        if (item.sudah) {
          alert('Angket sudah dinilai')
          return;
        }
        this.selected = item
      }, 150)
    },
    kembali() {
      setTimeout(() => {
        this.selected = {}
      }, 150)
    },
    setRating(index, rating) {
      this.data[index].rating = rating;
    },
    hoverRating(index, hover) {
      this.data[index].hover = hover;
    },
    submit() {
      const param = {
        id_siswa: this.$store.state.settings.user.id,
        id_angket: this.selected.id,
        jawaban: JSON.stringify(this.data)
      }

      const data = new FormData();
      data.append('id_siswa', param.id_siswa);
      data.append('id_angket', param.id_angket);
      data.append('jawaban', param.jawaban);

      this.isSaving = true;
      axios.post("https://api.pspp-integrated.com/api/v1/siswa/AngketController/submit", data)
        .then((r) => {
          this.isSaving = false;
          alert('Terimakasih sudah mengisi kuesioner');
          this.selected = {};
          this.loadMataDiklat()
        });
    },
    loadMataDiklat() {
      this.isLoading = true;
      api
        .get(
          "/v1/siswa/AngketController/getMataDiklat?id_kampus=" +
          this.$store.state.settings.user.id_kampus + '&jurusan=' + this.$store.state.settings.user.jurusan +
          '&id_siswa=' + this.$store.state.settings.user.id
        )
        .then((r) => {
          this.isLoading = false;
          if (r.valid) {
            this.dataMataDiklat = r.data
          }
        });
    }
  },
  mounted() {
    this.loadMataDiklat()
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background: #fff;
  color: #333;
  padding: 15px;
  font-weight: 600;
  display: grid;
  grid-template-columns: 40px 1fr;
  border-bottom: 1px solid #ccc;

  div {
    display: flex;
    align-items: center;
  }
}

.angket {
  padding: 20px;


  h3 {
    text-align: center;
  }

  .intro {
    p {
      font-size: 14px;
    }

    margin-bottom: 30px;
  }

  ol {
    padding-left: 20px;

    li {
      font-size: 14px;
      margin-bottom: 15px;

      p {
        margin: 0;
      }
    }
  }

  .info {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.star {
  font-size: 2rem;
  color: #ccc;
  cursor: pointer;
}

.star-filled {
  color: #ffd000;
  /* Warna kuning untuk bintang yang dipilih */
}

.mata-diklat {
  p {
    font-size: 14px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 15px 0;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:active {
        background: #eee;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }

      small {
        font-size: 12px;
      }

      span {
        font-size: 12px;
        padding: 4px 8px;
        background: var(--primary);
        color: #fff;
        border-radius: 4px;
      }
    }
  }
}

.form {
  h3 {
    text-align: left;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    font-size: 14px;
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    width: fit-content;
    font-size: 14px;
    padding: 8px;
    transition: all 0.3s ease;

    &:active {
      background-color: #eee;
    }

    div {
      display: flex;
      align-items: center;
    }
  }
}
</style>