<template>
  <div class="jadwal">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Jadwal KBM</div>
    </div>

    <div class="body" :class="jadwal.length <= 0 ? 'mt' : ''">
      <div v-if="jadwal.length <= 0">
        <p style="text-align:center;margin-top: 30px;">
          <img src="/static/icons/calendar.svg" width="80"/>
          <br/><br/>
          Belum ada jadwal KBM
        </p>
      </div>
      <div class="card" v-for="(item, index) in jadwal" :key="index">
        <div class="card-title">
          <span>{{ item.hari_mengajar }}, {{ item.tgl_mengajar }}</span>
        </div>
        <div class="card-body">
          <div v-if="item.jadwal.length > 0">
            <ul>
              <li v-for="(item, index) in item.jadwal" :key="index">
                <p>
                  <small>
                  {{ item.waktu_mulai }} - {{ item.waktu_selesai }} ({{
                    item.jumlah_sesi
                  }}
                  sesi)
                  </small>
                </p>
                <p>{{ item.mata_diklat }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "../api/Api";

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      jadwal: [],
    };
  },
  methods: {},
  mounted() {
    api
      .get(
        "/v1/siswa/JadwalController/get?id_siswa=" +
          this.$store.state.settings.user.id
      )
      .then((r) => {
        this.jadwal = r;
        console.log(r);
      });
  },
};
</script>

<style scoped lang="scss">
.jadwal {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }
  .body {
    background: #f7f7f7;
    padding: 15px;

    &.mt {
      background: #fff;
    }

    .card {
      background: #fff;
      border-radius: 6px;
      padding: 15px;
      box-shadow: 0px 1px 3px #aaa;
      margin-bottom: 20px;

      .card-title {
        border-bottom: 1px solid #ccc;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        small {
          font-weight: 400;
          color: #333;
        }
      }

      .card-body {

        ul {
          padding-left: 20px;
          margin: 0;
          li {
            margin-bottom: 15px;
            p {
              margin: 0;
              &:first-child {
                font-weight: 500;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      h1 {
        margin: 0;
        text-align: center;
      }

      h3 {
        margin: 0;
        text-align: center;
      }
    }
  }
}
</style>
