<template>
  <div class="nilai">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Nilai Ujian</div>
    </div>

    <div class="body" :class="hasil.length <= 0 ? 'mt' : ''">

      <div v-if="hasil.length <= 0">
        <p style="text-align:center;margin-top: 30px;">
          <img src="/static/icons/medal.svg" width="80"/>
          <br/><br/>
          Belum ada Nilai Ujian
        </p>
      </div>

      <div class="card" v-for="item, index in hasil" :key="index">
        <div class="card-title">
          <span>{{ item.ujian }}</span>
          <small>{{ reformatTgl(item) }}</small>
        </div>
        <div class="card-body">
          <h1>{{ item.nilai }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "../api/Api";
import moment from 'moment'

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      hasil: []
    };
  },
  methods: {
    reformatTgl(item) {
      return moment(item.tgl_ujian).format('DD MMM YYYY') + 
      " " + moment(item.tgl_ujian + " " +item.jam_mulai).format('HH:mm') +
      " - " + moment(item.tgl_ujian + " " +item.jam_selesai).format('HH:mm')
    }
  },
  mounted() {
    api
      .get(
        "/v1/siswa/UjianController/hasil?id_siswa=" +
          this.$store.state.settings.user.id
      )
      .then((r) => {
        this.hasil = r
      });
  },
};
</script>

<style scoped lang="scss">
.nilai {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }
  .body {
    background: #f7f7f7;
    padding: 15px;

    &.mt {
      background: #fff;
    }

    .card {
      background: #fff;
      border-radius: 6px;
      padding: 15px;
      box-shadow: 0px 1px 3px #aaa;
      margin-bottom: 20px;

      .card-title {
        border-bottom: 1px solid #ccc;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        small {
          font-weight: 400;
          color: #333;
        }
      }

      .card-body {
        padding: 15px;
      }

      h1 {
        margin: 0;
        text-align: center;      
      }

      h3 {
        margin: 0;
        text-align: center;
      }
    }
  }
}
</style>
