<template>
  <div class="search">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Cari Tiket Pesawat</div>
    </div>
    <div>
      <div class="param">
        <table class="">
          <tr>
            <td>Dari</td>
            <td>:</td>
            <td>{{ flightParam.namaKotaAsal }}</td>
          </tr>
          <tr>
            <td>Tujuan</td>
            <td>:</td>
            <td>{{ flightParam.namaKotaTujuan }}</td>
          </tr>
          <tr>
            <td>Tgl Terbang</td>
            <td>:</td>
            <td>{{ moment(flightParam.tglTerbang).format("DD MMM YYYY") }}</td>
          </tr>
          <tr>
            <td>Penumpang</td>
            <td>:</td>
            <td>
              <span v-if="flightParam.jumlahDewasa"
                >{{ flightParam.jumlahDewasa }} Dewasa</span
              >
              <span v-if="flightParam.jumlahAnak"
                >{{ flightParam.jumlahAnak }} Anak</span
              >
              <span v-if="flightParam.jumlahBayi"
                >{{ flightParam.jumlahBayi }} Bayi</span
              >
            </td>
          </tr>
        </table>
      </div>
      <ul>
        <li v-for="(item, index) in hasil" :key="index" @click="pilih(item)">
          <div>
            <div>
              <strong>{{ $validateMaskapai(item.flight[0].flight_num, item.param.airline_label) }}</strong>
              <table>
                <tr>
                  <td>Berangkat</td>
                  <td>: &nbsp;</td>
                  <td>
                    {{ item.flight[0].depart_time }} ({{
                      item.flight[0].depart_port
                    }})
                  </td>
                </tr>
                <tr>
                  <td>Tiba</td>
                  <td>:</td>
                  <td>
                    {{ item.flight[item.flight.length - 1].arrive_time }} ({{
                      item.flight[item.flight.length - 1].arrive_port
                    }})
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <div>
              <strong> Rp {{ item.grand_total | number }}</strong>
              <span>{{ item.kelas }}</span>
            </div>
          </div>
        </li>
      </ul>

      <p style="text-align: center" v-if="loading">
        <loading />
        <br />
        Mencari penerbangan dengan maskapai <br />
        <b>{{ maskapai }}</b>
      </p>

      <div style="text-align: center" v-if="noFlights">
        <p style="text-align: center">
          Jadwal penerbangan tidak ditemukan. <br/>
          Silakan ganti tanggal penerbangan. <br/>
        </p>
        <button @click="$router.go(-1)">Kembali</button>
      </div>

      <div style="text-align: center" v-if="error">
        <p style="text-align: center">
          <img src="/static/icons/multiply.svg" height="60" />
          <br />
          <br />
          Pencarian error, silakan coba lagi
        </p>
        <button @click="mulaiCari">Ulangi Pencarian</button>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import moment from "moment";
import * as api from "./../api/FlightApi";

export default {
  name: "Search",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loading: true,
      error: false,
      hasil: [],
      maskapai: "",
      flightParam: {},
      noFlights: false
    };
  },
  methods: {
    moment,
    cari(airlines, index) {
      this.maskapai = airlines[index].label;

      const param = {
        kota_asal: this.flightParam.kotaAsal,
        kota_tujuan: this.flightParam.kotaTujuan,
        tgl_terbang: this.flightParam.tglTerbang,
        jumlah_dewasa: this.flightParam.jumlahDewasa,
        jumlah_anak: this.flightParam.jumlahAnak
          ? this.flightParam.jumlahAnak
          : 0,
        jumlah_bayi: this.flightParam.jumlahBayi
          ? this.flightParam.jumlahBayi
          : 0,
        airline: airlines[index].airline,
        label: airlines[index].label,
      };

      const self = this;
      api
        .post("/v1/SoapController/searchFlight", param)
        .then((r) => {
          if (r.valid) {
            Object.keys(r.data).forEach((item) => {
              const flight = r.data[item];
              flight.grand_total =
                param.jumlah_dewasa * parseInt(flight.total) +
                param.jumlah_anak * parseInt(flight.total_anak || 0) +
                param.jumlah_bayi * parseInt(flight.total_infant || 0) +
                4000;

              self.hasil.push(flight);
              self.$store.commit("flights/setResult", flight);
            });
          }

          if (index < airlines.length - 1) {
            self.cari(airlines, index + 1);
          } else {
            self.loading = false;
            if (self.hasil.length <= 0) {
              this.noFlights = true;
            }
          }
        })
        .catch((e) => {
          self.loading = false;
          self.error = true;
          console.log("ERROR", e);
        });
    },
    pilih(item) {
      setTimeout(() => {
        this.$store.commit("flights/setSelected", item);
        this.$router.push("booking");
      });
    },
    mulaiCari() {
      this.error = false;
      this.loading = true;
      this.noFlights = false;
      this.hasil = [];

      const airlines = this.$store.state.flights.airlineList;
      this.$store.commit("flights/clearResult");

      this.cari(airlines, 0);
    }
  },
  mounted() {
    this.flightParam = this.$store.state.flights.param;

    this.mulaiCari();
  },
};
</script>

<style scoped lang="scss">
.search {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .param {
    border: 1px solid #ccc;
    margin: 15px 15px 0px;
    padding: 15px;
    border-radius: 6px;

    table {
      border-collapse: collapse;
      td:nth-child(2) {
        padding: 5px 8px;
      }
    }

    span {
      display: block;
    }
  }

  p.notif {
    background: #f5eb8e;
    padding: 15px;
    margin: 15px 15px 0px;
  }
  ul {
    list-style-type: none;
    padding: 15px;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      padding: 15px 0px;
      border-bottom: 1px solid #ccc;

      &:active {
        background: #eee;
      }

      table {
        border-collapse: collapse;
        padding: 0;
      }

      > div {
        display: flex;
        align-items: center;

        &:nth-child(2) {
          strong,
          span {
            display: block;
            text-align: right;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  button {
    background: var(--primary);
    color: #fff;
    font-weight: 600;
    padding: 15px;
    border: 0;
    outline: none;
    border-radius: 5px;
    width: 200px;
    margin: 0 auto;
  }
}
</style>
