<template>
  <div class="profil">
    <div class="logo">
      <div>
        <img src="./../assets/icons/kidora.png" />
      </div>
      <p>
        <b>PT Kidora Mandiri Investama</b>
      </p>
    </div>


    <div v-if="loading" style="text-align: center;background: #fff;">
      <Loading />
    </div>

    <div class="belum-lunas" v-if="statusBayar != 'LUNAS'">
      <div v-show="!loading">
        <p>
          Menu ini hanya bisa diakses bagi yang sudah melunasi pembayaran.
        </p>
        <p>
          Pembayaran Anda : <b>{{ statusBayar }}</b>
        </p>
      </div>
    </div>
    <div v-else>
      <div class="body">
        <div class="top">
          <div class="svg">
            <JobSearch />
          </div>
          <h1>Halo, {{ user.nama.toLowerCase() }}</h1>

          <form @submit.prevent="loadLowongan()">
            <div class="form-group">
              <input type="text" placeholder="Cari pekerjaan.." v-model="keyword" />
              <button type="button" class="btn btn-primary">Cari</button>
            </div>
          </form>
        </div>
      </div>
      <div class="bottom">

        <div>
          <div v-if="loadingLowongan" style="text-align: center">
            <Loading />
          </div>
          <div v-else>

            <p style="margin-bottom: 20px;">
              <b>Lowongan Pekerjaan</b>
            </p>
            <div class="list lowongan">
              <ul>
                <li v-for="(item, index) in dataLowongan" :key="index">
                  <router-link :to="'/lowongan/' + item.id">
                    <div class="title">
                      <span v-if="item.melamar">
                        Melamar
                        <img height="12px"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtUlEQVR4nN3SzUrDYBSE4WxFWrU/6s1UIYUIguKtunEvFCw0RS20N9LWqJtHCt8ixIhJv4XgbGfOy5zDSZJ/J5yhEws5xRIPMZAhFtgi3Rdygnks5Bg53jCOgczwjuuqeYBew3VyFMjqAvd4Rr9BkwJXP4WysO8LBjX+Eab4wM1vtS+xwWsZhi6eGkFKQxdYY4VzHOIxQG4bQUqwNPzG7tEmrZpUhVFo9om7b4E2CjeLg/y5vgBRK/T4iyDnZgAAAABJRU5ErkJggg==">
                      </span>
                      <p style="margin-bottom: 10px;">
                        {{ item.judul }}
                      </p>
                    </div>
                    <div class="detail">
                      <div>
                        <span
                          style="display: flex;align-items:center;color: #333;font-size: 13px;margin: 5px 0px;gap: 4px;">
                          <img src="https://img.icons8.com/?size=32&id=JhsIcmZ1Vss0&format=png" height="20px"
                            style="opacity: 0.7;" />
                          {{ item.perusahaan }}
                        </span>
                        <span style="display: flex;align-items: center;color: #333;font-size: 13px;gap: 4px;">
                          <img src="https://img.icons8.com/?size=32&id=tycnmjSklcNk&format=png" height="20px"" style="
                            opacity: 0.7;"> {{ item.penempatan }}
                        </span>
                      </div>
                      <div>
                        <small v-if="item.tgl_ditutup">
                          Ditutup pada<br />
                          {{ moment(item.tgl_ditutup).format("DD MMM YYYY") }}
                        </small>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";
import JobSearch from "./icons/JobSearch.vue";

import * as api from "../api/Api";
import moment from "moment";

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
    JobSearch
  },
  data() {
    return {
      user: this.$store.state.settings.user,

      statusBayar: "",
      keyword: "",

      loading: false,
      loadingLowongan: false,

      data: [],
      dataLowongan: [],
    };
  },
  methods: {
    moment,
    loadData() {
      this.loading = true;
      api.get("/v1/SiswaController/getStatusBayar?id=" + this.user.id).then((r) => {
        this.statusBayar = r.pembayaran;
        this.loading = false;

        if (this.statusBayar == "LUNAS") {
          this.loadLowongan();
        }
      });
    },
    loadLowongan() {
      this.loadingLowongan = true;
      api.get("/v1/kidora/LowonganController/get?id_siswa=" + this.user.id + "&keyword=" + this.keyword).then((res) => {
        this.dataLowongan = res.data;
        this.loadingLowongan = false;
      });
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.profil {
  height: 100vh;
  background: #F4F6FA;

  h1 {
    margin: 0;
  }

  .logo {
    text-align: center;
    height: 140px;
    background: #fff;

    img {
      height: 140px;
    }

    >div {
      height: 100px;
      overflow: hidden;
    }

    p {
      margin-bottom: 0;
    }

  }

  .body {
    background: #fff;
    padding: 20px 20px 30px;

    .top {
      background: var(--primary);
      border-radius: 10px;
      padding: 20px;

      h1 {
        color: #fff;
        font-size: 18px;
        text-transform: capitalize;
        margin: 0 0 20px;
      }

      form {
        .form-group {
          margin-bottom: 0;
          display: grid;
          grid-template-columns: 1fr 70px;
          gap: 10px;
        }

        .btn {
          margin-bottom: 0;
          background: #3F3F9B;
          padding: 0;
        }

        input {
          border: 0;
        }
      }

      .svg {
        display: flex;
        justify-content: center;
        margin: 20px 0 30px;

        svg {
          height: 100px;
        }
      }
    }
  }
}

.belum-lunas {
  background: #fff;
  padding: 20px;

  p {
    text-align: center;
  }
}

.card {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  margin: 20px 0;

  h3 {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    color: var(--primary);
    margin-bottom: 15px;
  }
}


.bottom {
  background: #F4F6FA;
  padding: 20px;

  p {
    margin: 0;
  }

  .berkas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 10px;

    .card {
      position: relative;
      transition: transform 0.3s ease;

      &:active {
        transform: scale(0.9);
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      .uploaded {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        height: 60px;
        transition: transform 0.3s ease;

        &:active {
          transform: scale(0.9);
        }
      }

      .file {
        width: 100%;
        height: 60px;
        border: 1px dashed #888;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 15px;
      }

      p {
        font-size: 14px;
        text-align: center;
      }

      .progress {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: #2c9cff;
      }
    }
  }

  .list {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        a {
          color: #2c9cff;
          text-decoration: none;
        }

        >div {
          &.relative {
            position: relative;
            color: #777;

            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;

              &:active {
                background: #eee;
              }
            }
          }

          p {
            font-size: 14px;

            span {
              border: 1px solid #ccc;
              padding: 5px;
              border-radius: 4px;
              cursor: pointer;

              &:active {
                background: #eee;
              }
            }
          }
        }
      }
    }

    &.lowongan {
      .title {
        span {
          background: var(--primary);
          color: #fff;
          font-size: 12px;
          padding: 4px 6px;
          border-radius: 4px;
          margin-bottom: 6px;
          display: flex;
          align-items: center;
          gap: 5px;
          width: fit-content;
        }
      }

      li {
        display: block;
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 20px;

        &:active {
          background: #eee;
        }

        a {


          .detail {
            display: grid;
            grid-template-columns: 1fr 100px;
            justify-content: space-between;
            align-items: center;

            >div {
              &:last-child {
                small {
                  display: block;
                  text-align: right;
                }
              }
            }
          }

          small {
            color: #777;
          }

          p {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
          }

          span {
            &.melamar {
              display: block;
              background-color: var(--primary);
              color: #fff;
              font-size: 12px;
              padding: 4px 8px;
              border-radius: 4px;
              width: 60px;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
