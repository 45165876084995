<template>
  <div class="page">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Data Siswa Detail</div>
    </div>

    <div class="body">

      <h1>{{ siswa.nama_lengkap.toUpperCase() }}</h1>
      <span>Menunggu Hasil Seleksi</span>

      <form>
        <div class="form-group">
          <label>Jurusan</label>
          <input type="text" readonly="true" v-model="siswa.jurusan">
        </div>
        <div class="form-group">
          <label>Angkatan</label>
          <input type="text" readonly="true" v-model="siswa.angkatan_2">
        </div>
        <div class="form-group">
          <label>Kampus</label>
          <input type="text" readonly="true" v-model="siswa.kampus">
        </div>
        <br />
        <div class="form-group">
          <label>Nama Lengkap</label>
          <input type="text" readonly="true" v-model="siswa.nama_lengkap">
        </div>
        <div class="form-group">
          <label>Jenis Kelamin</label>
          <input type="text" readonly="true" v-model="siswa.jenis_kelamin">
        </div>
        <div class="form-group">
          <label>Tempat lahir</label>
          <input type="text" readonly="true" v-model="siswa.tempat_lahir">
        </div>
        <div class="form-group">
          <label>Tgl lahir</label>
          <input type="text" readonly="true" v-model="siswa.tgl_lahir">
        </div>
        <div class="form-group">
          <label>Tinggi badan</label>
          <input type="text" readonly="true" v-model="siswa.tinggi_badan">
        </div>
        <div class="form-group">
          <label>Berat badan</label>
          <input type="text" readonly="true" v-model="siswa.berat_badan">
        </div>

        <br />

        <div class="form-group">
          <label>SMA</label>
          <input type="text" readonly="true" :value="siswa.sekolah + ' ' + siswa.provinsi_pendidikan">
        </div>
        <div class="form-group">
          <label>Tahun lulus</label>
          <input type="text" readonly="true" v-model="siswa.tahun_akhir_sma">
        </div>
        <div class="form-group">
          <label>Alamat</label>
          <input type="text" readonly="true" v-model="siswa.alamat_siswa">
        </div>
        <div class="form-group">
          <label>Provinsi</label>
          <input type="text" readonly="true">
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="text" readonly="true" v-model="siswa.email">
        </div>
        <div class="form-group">
          <label>No HP</label>
          <input type="text" readonly="true" v-model="siswa.no_hp_siswa">
        </div>

        <br />

      </form>

    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import { get } from '@/api/Api'

export default {
  components: {
    Back,
  },
  data() {
    return {
      siswa: {}
    }
  },
  methods: {
    async load() {
      const res = await get('/v1/marketing/siswa/get?id=' + this.$route.params.id)
      this.siswa = res.data.data[0]
    }
  },
  mounted() {
    this.load();
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background: #fff;
  color: #333;
  padding: 15px;
  font-weight: 600;
  display: grid;
  grid-template-columns: 40px 1fr;
  border-bottom: 1px solid #ccc;
}

.body {
  margin: 20px;

  h1 {
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    background: var(--primary);
    color: #fff;
    padding: 6px;
    border-radius: 4px;
  }

  form {
    margin-top: 30px;
  }
}
</style>