<template>
  <div class="tiket">
    <div class="navbar">
      <div @click="$router.push('/')">
        <back />
      </div>
      <div>Pesan Tiket Pesawat</div>
      <router-link to="/history" v-if="fiturAktif">
        <receipt />
      </router-link>
    </div>

    <!-- <div v-if="!fiturAktif">
      <p style="text-align: center;margin-top: 100px;">
        Fitur belum aktif
      </p>
    </div> -->
    <div>
      <div class="card">
        <p
          style="text-align: center"
          v-show="airportList.length <= 0 && airlineList.length <= 0"
        >
          <loading />
          <br />
          Sedang menyiapkan form pencarian...
        </p>
        <form
          @submit.prevent="cari"
          v-show="airportList.length > 0 && airlineList.length > 0"
          autocomplete="off"
        >
          <div class="form-group">
            <label>Dari</label>
            <plane class="icon plane-up" />
            <input
              type="text"
              v-model="kotaAsal"
              id="input-departure"
              @focus="startFilter('DEPARTURE')"
              @blur="exitInterval('DEPARTURE')"
            />
            <div class="autocomplete" v-if="departureOpen">
              <ul>
                <li
                  v-for="(item, index) in filteredAirports"
                  :key="index"
                  @click="selectCity(item, 'DEPARTURE')"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>Ke</label>
            <plane class="icon plane-down" />
            <input
              type="text"
              v-model="kotaTujuan"
              id="input-arrival"
              @focus="startFilter('ARRIVAL')"
              @blur="exitInterval('ARRIVAL')"
            />
            <div class="autocomplete" v-if="arrivalOpen">
              <ul>
                <li
                  v-for="(item, index) in filteredAirports"
                  :key="index"
                  @click="selectCity(item, 'ARRIVAL')"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>Tgl Berangkat</label>
            <calendar class="icon" />
            <input type="date" v-model="param.tglTerbang" />
          </div>
          <div style="display: grid; grid-template-columns: repeat(3, 1fr)">
            <div class="form-group">
              <label>Dewasa</label>
              <people class="icon" />
              <select v-model="param.jumlahDewasa">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label>Anak</label>
              <people class="icon" />
              <select v-model="param.jumlahAnak">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label>Bayi</label>
              <people class="icon" />
              <select v-model="param.jumlahBayi">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <button type="submit">Cari Penerbangan</button>
          </div>
        </form>
      </div>

      <div class="booking-terakhir" v-if="bookingTerakhir">
        <p><b>Booking Terakhir</b></p>

        <div
          class="booking"
          @click="$router.push('/history/' + bookingTerakhir.id)"
        >
          <div>
            <h3>
              {{ bookingTerakhir.bandara_asal }} -
              {{ bookingTerakhir.bandara_tujuan }}
            </h3>
            <span
              class="tiket-status"
              :class="{ issued: getStatus == 'Tiket Issued', checkout: getStatus == 'Belum Lunas'}"
            >
              <b>{{ getStatus }}</b>
            </span>
            <p>
              <img
                :src="
                  'https://apitama.xyz/flight-api/sampel_api_tiket_pesawat/images/' +
                  bookingTerakhir.kode_airline +
                  '.png'
                "
                height="30"
              />
            </p>
            <table>
              <tr>
                <td>Tgl Terbang</td>
                <td>:</td>
                <td>
                  {{
                    moment(bookingTerakhir.tgl_terbang).format("DD MMM YYYY")
                  }}
                </td>
              </tr>
              <tr>
                <td valign="top">Penumpang</td>
                <td valign="top">:</td>
                <td>
                  <span v-if="bookingTerakhir.jumlah_dewasa">
                    {{ bookingTerakhir.jumlah_dewasa }} Dewasa </span
                  ><br />
                  <span v-if="bookingTerakhir.jumlah_anak > 0">
                    {{ bookingTerakhir.jumlah_anak }} Anak </span
                  ><br />
                  <span v-if="bookingTerakhir.jumlah_bayi > 0">
                    {{ bookingTerakhir.jumlah_bayi }} Bayi
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";
import User from "./icons/User.vue";
import Plane from "./icons/Plane.vue";
import Calendar from "./icons/Calendar.vue";
import People from "./icons/People.vue";
import Receipt from "./icons/Receipt.vue";

import moment from "moment";
import * as psppApi from "../api/Api";
import * as api from "../api/FlightApi";

export default {
  name: "Tiket",
  components: {
    Back,
    Receipt,
    Loading,
    User,
    Plane,
    Calendar,
    People,
  },
  computed: {
    getStatus() {
      if (this.bookingTerakhir.status_issued == 1) {
        return "Tiket Issued";
      } else if (this.bookingTerakhir.status_bayar == 1) {
        return "Lunas";
      } else {
        return "Belum Lunas";
      }
    },
  },
  data() {
    return {
      fiturAktif: false,
      categoryOpen: false,
      overlayOpen: false,
      airportList: [],
      airlineList: [],
      kotaAsal: "",
      kotaTujuan: "",
      param: {
        jumlahDewasa: 1,
        jumlahAnak: 0,
        jumlahBayi: 0,
      },
      departureOpen: false,
      arrivalOpen: false,
      bookingTerakhir: {
        id: null,
      },
      loadingAirport: false,
      loadingAirlines: false,
      departureInterval: null,
      arrivalInterval: null,
      filteredAirports: [],
    };
  },
  methods: {
    moment,
    checkValue(tipe) {
      if (tipe === "DEPARTURE") {
        if (this.kotaAsal.length > 0) {
          this.departureOpen = true;
        } else {
          this.departureOpen = false;
        }
      } else {
        if (this.kotaTujuan.length > 0) {
          this.arrivalOpen = true;
        } else {
          this.arrivalOpen = false;
        }
      }
    },
    selectCity(item, tipe) {
      if (tipe === "DEPARTURE") {
        this.kotaAsal = item.label;
        this.departureOpen = false;
      } else {
        this.kotaTujuan = item.label;
        this.arrivalOpen = false;
      }
    },
    startFilter(tipe) {
      if (tipe === "DEPARTURE") {
        this.departureOpen = true;
        this.departureInterval = setInterval(() => {
          this.filterAirport("DEPARTURE");
          this.kotaAsal = document.getElementById("input-departure").value;
        }, 500);
      } else {
        this.arrivalOpen = true;
        this.arrivalInterval = setInterval(() => {
          this.filterAirport("ARRIVAL");
          this.kotaTujuan = document.getElementById("input-arrival").value;
        }, 500);
      }
    },
    exitInterval(tipe) {
      if (tipe === "DEPARTURE") {
        clearInterval(this.departureInterval);
      } else {
        clearInterval(this.arrivalInterval);
      }
    },
    filterAirport(tipe) {
      if (tipe === "DEPARTURE") {
        if (this.kotaAsal) {
          this.filteredAirports = this.airportList.filter((k) => {
            return k.label.toLowerCase().includes(this.kotaAsal.toLowerCase());
          });
        } else {
          this.filteredAirports = this.airportList;
        }
      } else {
        if (this.kotaTujuan) {
          this.filteredAirports = this.airportList.filter((k) => {
            return k.label
              .toLowerCase()
              .includes(this.kotaTujuan.toLowerCase());
          });
        } else {
          this.filteredAirports = this.airportList;
        }
      }
    },
    cari() {
      if (!this.kotaAsal || !this.kotaTujuan || !this.param.tglTerbang) {
        alert("Silakan lengkapi form");
        return;
      }

      const param = {
        kotaAsal: this.kotaAsal.match(/\((.*?)\)/)[1]
          ? this.kotaAsal.match(/\((.*?)\)/)[1]
          : this.kotaAsal,
        kotaTujuan: this.kotaTujuan.match(/\((.*?)\)/)[1]
          ? this.kotaTujuan.match(/\((.*?)\)/)[1]
          : this.kotaTujuan,
        tglTerbang: this.param.tglTerbang,
        jumlahDewasa: this.param.jumlahDewasa,
        jumlahAnak: this.param.jumlahAnak,
        jumlahBayi: this.param.jumlahBayi,
        namaKotaAsal: this.kotaAsal,
        namaKotaTujuan: this.kotaTujuan,
      };

      this.$store.commit("flights/setParam", param);
      this.$router.push("search");
    },
  },
  mounted() {
    const self = this;

    psppApi.get("/v1/FiturFlagController/get?fitur=tiket_pesawat").then((r) => {
      if (r.valid) {
        this.fiturAktif = r.data.status == 1;
      }
    });

    this.airportList = this.$store.state.flights.airportList
      ? this.$store.state.flights.airportList
      : [];
    this.airlineList = this.$store.state.flights.airlineList
      ? this.$store.state.flights.airlineList
      : [];
    this.filteredAirports = this.airportList;

    if (self.airportList.length <= 0 || self.airlineList.length <= 0) {
      self.loadingAirport = true;
      api.get("/v1/SoapController/airportList").then((r) => {
        self.airportList = r;
        self.filteredAirports = r;
        self.loadingAirport = false;

        self.$store.commit("flights/setAirportList", r);
      });

      self.loadingAirlines = true;
      api.get("/v1/SoapController/airlineList").then((r) => {
        self.airlineList = r.avail_airline;
        self.loadingAirlines = false;

        self.$store.commit("flights/setAirlineList", r);
      });
    }

    api
      .get(
        "/v1/BookingController/getBookingTerakhir?id_user=" +
          this.$store.state.settings.user.id
      )
      .then((r) => {
        self.bookingTerakhir = r;
      });
  },
};
</script>

<style scoped lang="scss">
.tiket {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    border-bottom: 1px solid #ccc;

    > a {
      display: flex;
      justify-content: flex-end;
      color: #fff;
    }
  }

  .card {
    margin: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px;

    form {
      background: #fff;
      padding: 15px;
      border-radius: 10px;
    }
  }

  .form-group {
    margin-bottom: 15px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
    label {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    input {
      background: #fff;
      padding: 6px 0px;
      padding-left: 30px;
      width: calc(100% - 30px);
      font-size: 14px;
      outline: none;
      border: 0;
      border-bottom: 1px solid #ccc;
      &:focus {
        border-bottom: 1px solid var(--primary);
      }
    }

    select {
      margin-left: 30px;
      width: 60%;
      padding: 3px 5px;
      outline: none;
      border: 0;
      border-bottom: 1px solid #ccc;
      background: #fff;
      &:focus {
        border-bottom: 1px solid var(--primary);
      }
    }

    .autocomplete {
      position: absolute;
      background: #fff;
      width: calc(100% - 30px);
      max-height: 200px;
      z-index: 9;
      overflow-y: auto;
      box-shadow: 0px 5px 10px #ccc;
      border-radius: 0px 0px 6px 6px;
      padding: 15px;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          padding: 10px 0px;
          border-bottom: 1px solid #ccc;
          &:active {
            background: #eee;
          }
        }
      }
    }

    button {
      width: 100%;
      background: var(--primary);
      color: #fff;
      padding: 14px;
      border: 0;
      border-radius: 4px;
      outline: none;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .card .icon {
    position: absolute;
  }

  .plane-up {
    transform: rotate(-30deg);
  }
  .plane-down {
    transform: rotate(30deg);
  }

  .booking-terakhir {
    margin: 15px;

    table {
      border-collapse: collapse;

      td {
        padding: 6px 0px;
        &:nth-child(2) {
          padding: 0px 10px;
        }
      }
    }

    .booking {
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 15px;
      position: relative;

      p,
      h3 {
        margin: 0;
        margin-bottom: 15px;
      }

      span.tiket-status {
        position: absolute;
        font-size: 12px;
        background-color: var(--primary);
        color: #fff;
        border-radius: 4px;
        padding: 6px 12px;
        right: 30px;
        margin-top: -35px;
        &.issued {
          background-color: #82cc70;
        }
        &.checkout {
          background-color: #f08b2d;
        }
      }
    }
  }
}
</style>
