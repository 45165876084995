<template>
  <div class="home">
    <p style="
        text-align: center;
        padding: 40px 0px 10px 0px;
        margin: 0 0 30px;
      ">
      <img src="https://pra.pspp-integrated.com/assets/img/logo.png" width="130" />
    </p>

    <div class="profile-bar">
      <div class="profile" @click="to('/profil')">
        <div>
          <p style="margin-left: 5px">
            <b>{{ user.nama }}</b>
            <br />
            <b style="font-weight: 500;font-size: 14px;">{{ user.no_daftar }}{{ user.prefix_daftar }}</b>
          </p>
        </div>
      </div>
      <div class="logout" @click="logout()">
        <img src="./../assets/icons/arrow.svg" height="20" />
      </div>
    </div>

    <div class="jadwal">
      <div class="jadwal-body">
        <div @click="to('/jadwal')">
          <div>
            <h3>JADWAL KBM</h3>
          </div>
          <div style="margin-left: 4px" class="jadwal-scan">
            <div v-if="jadwal.length > 0" class="jadwal-exist">
              <b> {{ jadwal[0].hari_mengajar }}, {{ jadwal[0].tgl_mengajar }} </b>
              <ul>
                <li v-for="(item, index) in jadwal[0].jadwal" :key="index">
                  <p>
                    {{ item.waktu_mulai }} - {{ item.waktu_selesai }} ({{
        item.jumlah_sesi
      }}
                    sesi)
                  </p>
                  <p>{{ item.mata_diklat }}</p>
                </li>
              </ul>
            </div>
            <div v-else class="jadwal-empty">
              <div>
                <p>Belum ada jadwal KBM</p>
              </div>
            </div>
          </div>
        </div>
        <div class="scan" @click="scan()">
          <div>
            <img src="./../assets/icons/scan.png" height="24" />
            Kehadiran
          </div>
        </div>
      </div>
      <div class="absensi" v-if="absen.id">
        <div>
          <p>Jam Masuk</p>
          <h3>{{ moment(absen.clock_in).format('HH:mm') }}</h3>
        </div>
        <div>
          <p>Jam Pulang</p>
          <h3>{{ absen.clock_out ? moment(absen.clock_out).format('HH:mm') : '-' }}</h3>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="main-menu">
        <div @click="to('/pemberkasan')">
          <p>
            <img src="./../assets/icons/file-storage.svg" />
          </p>
          <span> Pemberkasan</span>
        </div>

        <div @click="to('/soal')">
          <p>
            <img src="./../assets/icons/book.svg" />
          </p>
          <span> Soal-soal</span>
        </div>
        <div @click="to('/ujian')">
          <p>
            <img src="./../assets/icons/test.svg" />
          </p>
          <span> Ujian Online </span>
        </div>
        <div @click="to('/hasil-ujian')">
          <p>
            <img src="./../assets/icons/medal.svg" />
          </p>
          <span> Nilai Ujian </span>
        </div>
        <div @click="to('/praktek-terbang')">
          <p>
            <img src="./../assets/icons/travelling.svg" />
          </p>
          <span> Praktek Terbang</span>
        </div>
        <div @click="to('/sertifikat')">
          <p>
            <img src="./../assets/icons/certificate.svg" />
          </p>
          <span> Request Sertifikat</span>
        </div>
        <div @click="to('/wisuda')">
          <p>
            <img src="./../assets/icons/graduated.png" />
          </p>
          <span> Daftar Wisuda</span>
        </div>
        <div @click="to('/pembayaran')">
          <p>
            <img src="./../assets/icons/pembayaran.png" />
          </p>
          <span> Pembayaran</span>
        </div>
        <div @click="to('/kidora')">
          <p style="padding:0;margin:4px 0;">
            <img src="./../assets/icons/kidora.png" style="height:70px;" />
          </p>
          <span> Kidora</span>
        </div>
        <div @click="to('/marketing')">
          <p style="padding:0;margin:20px 0 4px;">
            <img src="./../assets/icons/siswa.png" style="height:40px;" />
          </p>
          <span> Siswa Get Siswa</span>
        </div>
        <div @click="to('/refund')">
          <p style="padding:0;margin:20px 0 4px;">
            <img src="./../assets/icons/cashback.png" style="height:40px;" />
          </p>
          <span> Refund Tablet</span>
        </div>
        <div @click="to('/angket')">
          <p style="padding:0;margin:20px 0 4px;">
            <img src="./../assets/icons/rating.png" style="height:40px;" />
          </p>
          <span> Angket Penilaian</span>
        </div>
        <div @click="to('/tiket')" v-if="false">
          <p>
            <img src="./../assets/icons/airplane-ticket.svg" />
          </p>
          <span> Tiket Pesawat</span>
        </div>
        <div @click="to('/paket-wisata')" v-if="false">
          <p>
            <img src="./../assets/icons/wisata.png" />
          </p>
          <span> Paket Wisata</span>
        </div>
      </div>
    </div>

    <span class="versi">Versi 1.1.0</span>
  </div>
</template>

<script>
import Plane from "./icons/Plane.vue";
import Calendar from "./icons/Calendar.vue";
import User from "./icons/User.vue";
import People from "./icons/People.vue";

import moment from "moment";
import * as psppApi from "./../api/Api";

export default {
  name: "Home",
  components: {
    User,
    Plane,
    Calendar,
    People,
  },
  data() {
    return {
      user: {},

      jadwal: [],
      absen: {}
    };
  },
  methods: {
    moment,
    logout() {
      localStorage.clear();
      location.reload();
    },
    to(to) {
      setTimeout(() => {
        this.$router.push(to);
      }, 150);
    },
    scan() {
      this.$router.push('/scan')
    }
  },
  mounted() {
    if (!this.$store.state.settings.user.id) {
      this.$router.push("/login");
    }

    this.user = this.$store.state.settings.user;

    // api.get("/v1/SoapController/airportList").then((r) => {
    //   this.$store.commit("flights/setAirportList", r);
    // });

    // api.get("/v1/SoapController/airlineList").then((r) => {
    //   this.$store.commit("flights/setAirlineList", r);
    // });

    psppApi
      .get("/v1/siswa/JadwalController/getToday?id_siswa=" + this.user.id)
      .then((r) => {
        this.jadwal = r.jadwal;
        this.absen = r.absen;
      });
  },
};
</script>

<style scoped lang="scss">
.home {
  background: #fff;
  position: relative;
  height: 100vh;

  .navbar {
    color: #333;
    padding: 18px 15px;
    font-weight: 600;
    font-size: 18px;
  }

  .profile-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
  }

  .profile {
    display: grid;
    grid-template-columns: 1fr;

    p {
      margin: 0;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .jadwal {
    p.logo {
      text-align: center;
    }

    .jadwal-body {
      display: grid;
      grid-template-columns: 1fr 100px;
      gap: 5px;

      h3 {
        margin: 4px;
        font-size: 16px;
      }

      .scan {
        display: grid;
        place-items: center;
        background: #fff;
        border-radius: 7px;
        color: #333;
        font-size: 14px;
        height: 80px;

        >div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 6px;

          img {
            display: block;
            width: 24px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .absensi {
      border-top: 1px solid #fff;
      margin-top: 15px;
      padding-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      p,
      h3 {
        margin: 0;
        text-align: center;
      }

      p {
        margin-bottom: 5px;
      }
    }

    margin: 15px 30px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px #aaa;
    background: var(--primary);
    color: #fff;

    &:active {
      background: #2c9cff;
    }

    .icon {
      width: 30px;
    }

    ul {
      padding-left: 20px;
      margin: 0;
      margin-top: 15px;

      li {
        margin-bottom: 15px;

        p {
          margin: 0;

          &:first-child {
            font-weight: 500;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .body {
    background: #fff;
    padding: 15px 0px;
  }

  .main-menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    margin: 0px 15px;

    div,
    a {
      background: #fff;
      color: #333;
      text-align: center;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;

      &:active {
        opacity: 0.5;
      }

      p {
        padding: 10px;
        text-align: center;
      }

      img {
        height: 30px;
      }
    }
  }

  .versi {
    display: block;
    font-size: 12px;
    margin-top: 60px;
    margin-left: 30px;
    padding-bottom: 15px;
  }
}
</style>
