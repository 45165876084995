<template>
  <div class="profil">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Profil Siswa</div>
    </div>

    <div class="body">
      <div class="card">
        <div class="card-body">
          <h3>{{ user.nama_lengkap }}</h3>
        </div>
      </div>

      <div class="card">
        <div class="card-title">Informasi Akademik</div>
        <div class="card-body">
          <div class="inline-form">
            <span>No Daftar</span>
            <label>{{ user.no_daftar }}</label>
          </div>
          <div class="inline-form">
            <span>Kampus</span>
            <label>{{ user.kampus }}</label>
          </div>
          <div class="inline-form">
            <span>Prodi</span>
            <label>{{ user.jurusan }}</label>
          </div>
          <div class="inline-form">
            <span>Angkatan</span>
            <label>{{ user.angkatan }}</label>
          </div>
          <div class="inline-form">
            <span>Kelas</span>
            <label>{{ user.kelas }}</label>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-title">Data Diri</div>
        <div class="card-body">
          <div class="inline-form">
            <span>Nama Lengkap</span>
            <label>{{ user.nama_lengkap }}</label>
          </div>
          <div class="inline-form">
            <span>Agama</span>
            <label>{{ user.agama }}</label>
          </div>
          <div class="inline-form">
            <span>Jenis Kelas</span>
            <label>{{ user.jenis_kelamin }}</label>
          </div>
          <div class="inline-form">
            <span>Tempat Lahir</span>
            <label>{{ user.tempat_lahir }}</label>
          </div>
          <div class="inline-form">
            <span>Tgl Lahir</span>
            <label>{{ user.tgl_lahir }}</label>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-title">Kontak</div>
        <div class="card-body">
          <div class="inline-form">
            <span>Alamat</span>
            <label>{{ user.alamat_siswa }}</label>
          </div>
          <div class="inline-form">
            <span>No HP</span>
            <label>{{ user.no_hp_siswa }}</label>
          </div>
          <div class="inline-form">
            <span>Email</span>
            <label style="text-transform: lowercase">{{ user.email }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import axios from "axios";

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      user: {},
    };
  },
  mounted() {
    const user = this.$store.state.settings.user;
    axios
      .get(
        "https://api.pspp-integrated.com/api/v1/siswa/ProfilController/get?id=" +
          user.id
      )
      .then((r) => {
        this.user = r.data.data;
      });
  },
};
</script>

<style scoped lang="scss">
.profil {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }
  .body {
    background: #f7f7f7;
    padding: 15px;

    table {
      width: 100%;
      td {
        padding: 5px 0px;
      }
    }

    .card {
      background: #fff;
      border-radius: 6px;
      padding: 15px;
      box-shadow: 0px 1px 3px #aaa;
      margin-bottom: 20px;

      .card-title {
        border-bottom: 1px solid #ccc;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      .inline-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          color: #555;
        }
        label {
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      h3 {
        margin: 0;
        text-align: center;
      }
    }
  }
}
</style>
