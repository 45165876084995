<template>
  <div class="profil">
    <div style="text-align: center" v-if="loading">
      <loading />
    </div>

    <div class="body" v-else>

      <div class="back" @click="$router.back()">
        <back />
      </div>

      <div class="top">
        <h1>
          {{ data.judul }}
        </h1>

        <div class="info">
          <span style="display: flex;align-items:center;color: #333;font-size: 13px;margin: 5px 0px;gap: 4px;">
            <img src="https://img.icons8.com/?size=32&id=JhsIcmZ1Vss0&format=png" height="20px" style="opacity: 0.7;" />
            {{ data.perusahaan }}
          </span>
          <span style="display: flex;align-items: center;color: #333;font-size: 13px;gap: 4px;">
            <img src="https://img.icons8.com/?size=32&id=tycnmjSklcNk&format=png" height="20px"" style=" opacity: 0.7;">
            {{ data.penempatan }}
          </span>
        </div>

        <div class="log" v-if="data.history">
          <p>
            <b>Aktivitas lamaran</b>
          </p>
          <ul>
            <li v-for="item, index in data.history" :key="index">
              <div>- {{ item.status }}</div>
              <div>{{ moment(item.waktu).format('DD/MM/YY') }}</div>
            </li>
          </ul>
        </div>
        <div class="upload" v-if="showUploadButton && !data.history">
          <span style="display: flex;align-items: center;gap: 10px;" v-if="persen == ''">
            Upload berkas lamaran
            <img src="https://img.icons8.com/?size=48&id=tWt9XCfjK1WM&format=png" height="24" />
          </span>
          <span v-else>{{ persen < 100 ? 'Mengunggah ' + persen + '%' : 'Menyelesaikan...' }}</span>

              <input type="file" @change="(e) => { upload(e); }" accept="application/pdf">
              <div class="border-upload" :style="`width: ${uploadWidth}`"></div>
        </div>
        <button v-if="!data.history && !showUploadButton" type="button" class="btn btn-primary"
          :disabled="loadingLamaran" @click="showUploadButton = true">
          Lamar Pekerjaan Ini
        </button>
      </div>

      <div class="keterangan">
        <p>
          <b>Keterangan Pekerjaan</b>
        </p>
        <div v-html="data.keterangan" class="content-area"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import moment from "moment";
import axios from 'axios';

import * as api from "../api/Api";

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loading: true,
      loadingLamaran: false,
      showUploadButton: false,

      data: {},
      uploadWidth: '',
      persen: 0
    };
  },
  methods: {
    moment,
    loadData() {
      api
        .get(
          "/v1/kidora/LowonganController/get?id=" +
          this.$route.params.id +
          "&id_siswa=" +
          this.$user.id
        )
        .then((res) => {
          this.data = res.data;
          this.loading = false;
          this.loadingLamaran = false;
        });
    },
    upload(e) {
      const [file] = e.target.files;

      const formData = new FormData();
      formData.append('berkas', file);

      const config = {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          this.uploadWidth = percentCompleted + "%";
          this.persen = percentCompleted;
        },
      };

      axios
        .post(
          "https://api.psppcdn.com/v1/kidora/berkas/upload?id_user=" +
          this.$store.state.settings.user.id,
          formData,
          config
        )
        .then((res) => {
          this.lamar(res.data.data.berkas);
        })
        .catch(function (err) {
          console.log(err.message);
        });
    },
    lamar(filename) {
      this.loadingLamaran = true;

      const data = {
        id_lowongan: this.$route.params.id,
        id_siswa: this.$user.id,
        berkas: filename
      };

      api.postJson("/v1/kidora/LowonganController/lamar", data).then((r) => {
        this.loadData();
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.profil {

  .body {
    padding: 20px;
    color: #444;

    .back {
      padding: 10px;
      width: fit-content;
      border-radius: 6px;

      &:active {
        background: #F4F6FA;
      }
    }

    .top {
      background: #F4F6FA;
      padding: 15px;
      border-radius: 10px;
      margin-top: 5px;

      .info {
        margin-bottom: 20px;
      }

      .btn-primary {
        background: #3F3F9B;
        margin-bottom: 0;

        &:disabled {
          background: #acacff;
        }
      }
    }

    .keterangan {
      margin: 20px 0;

      >p {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    h1 {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      font-size: 18px;
      margin: 0 0 15px;
    }

    p {
      margin: 0;
      font-size: 22px;
    }

    h2,
    h3 {
      font-size: 14px;
    }

    p,
    ul {
      font-size: 14px;
    }
  }

  a {
    color: rgb(0, 153, 255);
    text-decoration: none;
  }
}

.log {
  margin-top: 15px;

  ul {
    margin: 10px 0;
    padding: 0;
    list-style-type: disc;

    li {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      padding: 3px 0;

      color: #333;

      >div {
        &:last-child {
          font-variant-numeric: tabular-nums;
        }
      }
    }
  }
}

.upload {
  border: 1px dashed #333;
  background: #fff;
  border-radius: 6px;
  position: relative;
  padding: 16px;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:active {
    transform: scale(0.9);
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .border-upload {
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 6px;
    width: 0;
    background: var(--primary);
  }
}
</style>

<style lang="scss">
.content-area {
  p {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }
}
</style>