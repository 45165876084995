// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.prototype.$user = store.state.settings.user
Vue.prototype.$validateMaskapai = (flightNumber, airlineLabel) => {
  const kode = flightNumber.substring(0, 2);

  const maskapai = {
    "IW": "Wings Air",
    "JT": "Lion Air",
    "ID": "Batik Air",
    "IU": "Super Air Jet",
  }

  if (maskapai[kode]) {
    return maskapai[kode];
  } else {
    return airlineLabel;
  }
}

Vue.directive("uppercase", {
  update(el) {
    el.value = el.value.toUpperCase();
  },
});

Vue.filter('number', function (value) {
  if (value || value == 0)
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  else
    return ''
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
