<template>
  <div class="search">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Isi Form Booking</div>
    </div>

    <div class="flight">
      <div v-if="flight.param">
        <p>
          {{ hari(moment(flight.param.date).format("ddd")) }},
          {{ moment(flight.param.date).format("DD MMM YYYY") }}
        </p>
        <div style="justify-content: space-between">
          <div style="border: 0; padding: 0; display: flex">
            <img
              :src="
                'https://apitama.xyz/flight-api/sampel_api_tiket_pesawat/images/' +
                $validateMaskapai(
                  flight.flight[0].flight_num,
                  flight.param.airline
                ) +
                '.png'
              "
              width="60"
            />
            <b>{{ flight.param.depart }} - {{ flight.param.arrive }}</b>
          </div>
        </div>

        <span class="kelas">{{ flight.kelas }}</span>

        <div class="flight-detail">
          <div style="border: 0; padding: 0; height: 100%">
            <p>
              <plane />
              {{ flight.flight.length > 1 ? "Transit" : "Langsung" }}
            </p>
            <ul>
              <li v-for="(item, index) in flight.flight" :key="index">
                {{ item.depart_port }} - {{ item.arrive_port }} <br />
                <small
                  >{{ moment(item.depart_datetime).format("HH:mm") }} -
                  {{ moment(item.arrive_datetime).format("HH:mm") }}</small
                >
              </li>
            </ul>
          </div>
          <div style="border: 0; padding: 0; height: 100%">
            <p>
              <people />
              Penumpang
            </p>
            <ul>
              <li>
                {{ flight.param.adult }} Dewasa <br />
                <small
                  >{{ flight.param.adult }} x {{ flight.total | number }}</small
                >
              </li>
              <li v-if="flight.param.child > 0">
                {{ flight.param.child }} Anak<br />
                <small
                  >{{ flight.param.child }} x
                  {{ flight.total_anak | number }}</small
                >
              </li>
              <li v-if="flight.param.infant > 0">
                {{ flight.param.infant }} Bayi<br />
                <small
                  >{{ flight.param.infant }} x
                  {{ flight.total_infant | number }}</small
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="total">
          <p>
            <span style="display: block; margin-top: 5px">Total</span>
          </p>
          <b style="text-align: right">
            Rp {{ flight.grand_total | number }}
          </b>
        </div>
      </div>
    </div>

    <div class="form-dewasa" v-for="(item, index) in dewasa" :key="item.id">
      <form>
        <p>
          <b>Penumpang Dewasa {{ index + 1 }}</b>
        </p>
        <div class="form-group">
          <label>No Identitas</label>
          <input type="number" v-model="item.id_card_num" />
        </div>
        <div class="form-group">
          <label>Title</label>
          <select v-model="item.title">
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Ms">Ms</option>
          </select>
        </div>
        <div class="form-group">
          <label>Nama Lengkap</label>
          <input type="text" v-model="item.nama" />
        </div>
        <div class="form-group">
          <label>Tgl Lahir</label>
          <div class="tgl-lahir">
            <input type="number" v-model="item.tgl" />
            <select v-model="item.bln">
              <option value="01">Januari</option>
              <option value="02">Februari</option>
              <option value="03">Maret</option>
              <option value="04">April</option>
              <option value="05">Mei</option>
              <option value="06">Juni</option>
              <option value="07">Juli</option>
              <option value="08">Agustus</option>
              <option value="09">September</option>
              <option value="10">Oktober</option>
              <option value="11">November</option>
              <option value="12">Desember</option>
            </select>
            <input type="number" v-model="item.thn" />
          </div>
        </div>
      </form>
    </div>

    <form class="form-anak" v-for="(item, index) in anak" :key="item.id">
      <p>
        <b>Penumpang Anak {{ index + 1 }}</b>
      </p>
      <div class="form-group">
        <label>No Identitas</label>
        <input type="number" v-model="item.id_card_num" />
      </div>
      <div class="form-group">
        <label>Title</label>
        <select v-model="item.title">
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Ms">Ms</option>
        </select>
      </div>
      <div class="form-group">
        <label>Nama Lengkap</label>
        <input type="text" v-model="item.nama" />
      </div>
      <div class="form-group">
        <label>Tgl Lahir</label>
        <div class="tgl-lahir">
          <input type="number" v-model="item.tgl" />
          <select v-model="item.bln">
            <option value="01">Januari</option>
            <option value="02">Februari</option>
            <option value="03">Maret</option>
            <option value="04">April</option>
            <option value="05">Mei</option>
            <option value="06">Juni</option>
            <option value="07">Juli</option>
            <option value="08">Agustus</option>
            <option value="09">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">Desember</option>
          </select>
          <input type="number" v-model="item.thn" />
        </div>
      </div>
    </form>

    <form class="form-dewasa" v-for="(item, index) in bayi" :key="item.id">
      <p>
        <b>Penumpang Bayi {{ index + 1 }}</b>
      </p>
      <div class="form-group">
        <label>No Identitas</label>
        <input type="number" v-model="item.id_card_num" />
      </div>
      <div class="form-group">
        <label>Title</label>
        <select v-model="item.title">
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Ms">Ms</option>
        </select>
      </div>
      <div class="form-group">
        <label>Nama Lengkap</label>
        <input type="text" v-model="item.nama" />
      </div>
      <div class="form-group">
        <label>Tgl Lahir</label>
        <div class="tgl-lahir">
          <input type="number" v-model="item.tgl" />
          <select v-model="item.bln">
            <option value="01">Januari</option>
            <option value="02">Februari</option>
            <option value="03">Maret</option>
            <option value="04">April</option>
            <option value="05">Mei</option>
            <option value="06">Juni</option>
            <option value="07">Juli</option>
            <option value="08">Agustus</option>
            <option value="09">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">Desember</option>
          </select>
          <input type="number" v-model="item.thn" />
        </div>
      </div>
    </form>

    <form>
      <p>
        <b>Kontak</b>
      </p>
      <div class="form-group">
        <label>Nama</label>
        <input type="tel" v-model="kontak.name" />
      </div>
      <div class="form-group">
        <label>No HP</label>
        <input type="tel" v-model="kontak.mobile_number" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="email" v-model="kontak.email" />
      </div>
    </form>

    <button type="button" @click="book" v-if="!loading">
      Booking Sekarang
    </button>
    <span v-if="error" style="color: #f00"
      >Terjadi kesalahan, silakan coba lagi</span
    >
    <p style="text-align: center" v-if="loading">
      <loading />
    </p>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Plane from "./icons/Plane.vue";
import People from "./icons/People.vue";
import Loading from "./icons/Loading.vue";

import moment from "moment";
import * as api from "./../api/FlightApi";

export default {
  name: "Search",
  components: {
    Back,
    Plane,
    People,
    Loading,
  },
  data() {
    return {
      loading: false,
      error: false,
      hasil: [],
      dewasa: [],
      anak: [],
      bayi: [],
      flight: {},
      kontak: {
        name: "",
        mobile_number: "",
        email: "",
      },
    };
  },
  methods: {
    moment,
    hari(day) {
      const days = {
        Sun: "Minggu",
        Mon: "Senin",
        Tue: "Selasa",
        Wed: "Rabu",
        Thu: "Kamis",
        Fri: "Jumat",
        Sat: "Sabtu",
      };

      return days[day];
    },
    book() {
      this.loading = true;
      this.error = false;

      this.dewasa.forEach((item) => {
        item.date_of_birth = item.thn + "-" + item.bln + "-" + item.tgl;
        item.basic = this.flight.basic;
        item.adm = this.flight.adm;
        item.tax = this.flight.tax;
        item.fuel = this.flight.fuel;
        item.iwjr = this.flight.iwjr;
        item.total = this.flight.total;

        if (item.title == "Mr") {
          item.gender = "L";
        } else {
          item.gender = "P";
        }
      });

      this.anak.forEach((item) => {
        item.date_of_birth = item.thn + "-" + item.bln + "-" + item.tgl;
        item.basic = this.flight.basic_anak;
        item.adm = this.flight.adm_anak;
        item.tax = this.flight.tax_anak;
        item.fuel = this.flight.fuel_anak;
        item.iwjr = this.flight.iwjr_anak;
        item.total = this.flight.total_anak;

        if (item.title == "Mr") {
          item.gender = "L";
        } else {
          item.gender = "P";
        }
      });

      this.bayi.forEach((item) => {
        item.date_of_birth = item.thn + "-" + item.bln + "-" + item.tgl;
        item.basic = this.flight.basic_infant;
        item.adm = this.flight.adm_infant;
        item.tax = this.flight.tax_infant;
        item.fuel = this.flight.fuel_infant;
        item.iwjr = this.flight.iwjr_infant;
        item.total = this.flight.total_infant;

        if (item.title == "Mr") {
          item.gender = "L";
        } else {
          item.gender = "P";
        }
      });

      const param = {
        traveller: {
          adult: this.dewasa,
          child: this.anak,
          infant: this.bayi,
        },
        kelas: this.flight.kelas,
        contact: this.kontak,
        id_user: this.$store.state.settings.user.id,
        fares_id: this.flight.id,
        kode_airline: this.flight.param.airline,
        airline: this.flight.param.airline_label,
        kota_asal: this.flight.flight[0].depart_city,
        kota_tujuan:
          this.flight.flight[this.flight.flight.length - 1].arrive_city,
        bandara_asal: this.flight.flight[0].depart_port,
        bandara_tujuan:
          this.flight.flight[this.flight.flight.length - 1].arrive_port,
        tgl_terbang: this.flight.flight[0].depart_datetime,
        jumlah_dewasa: this.dewasa.length,
        jumlah_anak: this.anak.length,
        jumlah_bayi: this.bayi.length,
        flight: this.flight.flight,
        is_insuranced: 1,
      };

      this.$store.commit("flights/setBooking", param);

      if (!param.contact.name) {
        alert("Nama kontak tidak boleh kosong");
        return;
      } else if (!param.contact.mobile_number) {
        alert("No HP kontak tidak boleh kosong");
        return;
      } else if (!param.contact.email) {
        alert("Email kontak tidak boleh kosong");
        return;
      }

      const self = this;
      api
        .postJson("/v1/SoapController/book", param)
        .then((r) => {
          self.$store.commit("flights/updateBooking", {
            id: r.id_booking,
            grand_total: r.grand_total,
          });
          self.$store.commit("flights/setPayment", {});

          self.loading = false;
          if (r.valid == 1) {
            self.$router.push("/payment");
          } else {
            alert("Pastikan semua data sudah diisi");
          }
        })
        .catch((e) => {
          self.loading = false;
          self.error = true;
        });
    },
  },
  mounted() {
    this.flight = this.$store.state.flights.selected;
    const param = this.$store.state.flights.selected.param;

    this.flight.total = this.flight.total - 500;
    this.flight.total_anak = this.flight.total_anak - 500;
    this.flight.total_infant = this.flight.total_infant - 500;

    for (let i = 0; i < parseInt(param.adult); i++) {
      this.dewasa.push({
        id: parseInt(Date.now()) + i,
        title: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
      });
    }

    for (let i = 0; i < parseInt(param.child); i++) {
      this.anak.push({
        id: parseInt(Date.now()) + 10,
        title: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
      });
    }

    for (let i = 0; i < parseInt(param.infant); i++) {
      this.bayi.push({
        id: parseInt(Date.now()) + 20,
        title: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
      });
    }

    // const booking = this.$store.state.flights.booking;
    // if (booking.traveller) {
    //   this.dewasa = [];
    //   booking.traveller.adult.forEach((item) => {
    //     this.dewasa.push({
    //       id: item.id,
    //       title: item.title,
    //       nama: item.nama,
    //       tgl: item.tgl,
    //       bln: item.bln,
    //       thn: item.thn,
    //       date_of_birth: item.date_of_birth,
    //     });
    //   });

    //   this.anak = [];
    //   booking.traveller.child.forEach((item) => {
    //     this.anak.push({
    //       id: item.id,
    //       title: item.title,
    //       nama: item.nama,
    //       tgl: item.tgl,
    //       bln: item.bln,
    //       thn: item.thn,
    //       date_of_birth: item.date_of_birth,
    //     });
    //   });

    //   this.bayi = [];
    //   booking.traveller.infant.forEach((item) => {
    //     this.bayi.push({
    //       id: item.id,
    //       title: item.title,
    //       nama: item.nama,
    //       tgl: item.tgl,
    //       bln: item.bln,
    //       thn: item.thn,
    //       date_of_birth: item.date_of_birth,
    //     });
    //   });
    // }

    // if (booking.contact) {
    //   this.kontak = {
    //     name: booking.contact.name,
    //     mobile_number: booking.contact.mobile_number,
    //     email: booking.contact.email,
    //   };
    // }
  },
};
</script>

<style scoped lang="scss">
.search {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .flight {
    margin: 15px;
    border: 1px solid #ccc;
    border-radius: 6px;
    position: relative;

    span.kelas {
      position: absolute;
      right: 30px;
      margin-top: -60px;
      font-size: 11px;
      background: var(--primary);
      color: #fff;
      padding: 4px 8px;
      border-radius: 4px;
    }

    > div {
      padding: 15px;

      p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 14px;
      }

      > div {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        img {
          margin-right: 10px;
        }
      }

      .flight-detail {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border: 0;
        padding: 0;

        > div {
          display: block;
        }

        p {
          margin: 15px 0px 5px;
          display: flex;
          div {
            border: 0;
            padding: 0;
            margin-right: 5px;
          }
        }
        ul {
          margin: 0;
          padding-left: 20px;
          li {
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .total {
        margin-top: 15px;
        border: 0;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: 600;
          margin: 0;
        }

        b {
          font-size: 22px;
        }
      }
    }
  }

  form {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    .form-group {
      margin-bottom: 15px;

      label {
        display: block;
        font-size: 14px;
        margin-bottom: 4px;
      }
      select,
      input {
        padding: 10px;
        width: 100%;
        outline: none;
        border: 1px solid #999;
        border-radius: 4px;
        background: #fff;

        &:focus {
          border: 1px solid var(--primary);
        }
      }

      input {
        width: calc(100% - 22px);
      }

      .tgl-lahir {
        display: grid;
        grid-template-columns: 80px 1fr 120px;
        grid-column-gap: 10px;
      }
    }
  }

  button {
    background: var(--primary);
    color: #fff;
    padding: 15px;
    width: calc(100% - 30px);
    text-align: center;
    text-transform: uppercase;
    outline: none;
    border: 0;
    margin: 15px;
    border-radius: 6px;
    margin-bottom: 30px;
    font-weight: 600;
  }
}
</style>
