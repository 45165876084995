<template>
  <div class="refund">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Refund Tablet</div>
    </div>
    <div class="body">
      <p class="notifikasi">
        Refund tablet android akan ditransfer ke rekening berikut ini, silakan diisi dengan teliti.
      </p>

      <img :src="this.param.bukti_transfer" width="100%" v-if="this.param.bukti_transfer" />

      <form @submit.prevent="save()" :class="{ 'disabled': sudah }">
        <div class="form-group">
          <label>Nama Bank</label>
          <input type="text" v-model="param.bank" :disabled="sudah" required>
        </div>
        <div class="form-group">
          <label>No Rekening</label>
          <input type="tel" v-model="param.no_rekening" :disabled="sudah" required>
        </div>
        <div class="form-group">
          <label>Nama Pemilik Rekening</label>
          <input type="text" v-model="param.nama_pemilik_rekening" :disabled="sudah" required>
        </div>
        <button type="submit" class="btn btn-primary" :disabled="sudah || loading">
          Ajukan Refund
        </button>
      </form>
    </div>
  </div>
</template>

<script>

import Back from "./icons/Back.vue";
import * as api from "../api/Api";

export default {
  components: {
    Back
  },
  data() {
    return {
      loading: false,

      sudah: false,
      param: {}
    }
  },
  methods: {
    async load() {
      const res = await api.get('/v1/siswa/RefundController/get?id_siswa=' + this.$store.state.settings.user.id)
      if (res.data != null) {
        this.sudah = true;
        this.param = res.data;
      }
    },
    async save() {
      const user = this.$store.state.settings.user;
      this.param.id_siswa = user.id;
      this.param.nama_lengkap = user.nama;
      this.param.no_daftar = user.no_daftar;
      this.param.kampus = user.kampus;
      this.param.jurusan = user.jurusan;
      this.param.angkatan = user.angkatan;

      this.loading = true;
      const res = await api.post('/v1/siswa/RefundController/save', this.param);
      this.loading = false;

      alert('Refund berhasil diajukan');
      this.sudah = true;
    }
  },
  mounted() {
    this.load();
  }
}
</script>


<style scoped lang="scss">
.refund {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .notifikasi {
    background: #F6C863;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 6px;
  }

  .body {
    padding: 20px;
  }

  form {
    &.disabled {
      opacity: 0.6;
    }
  }
}
</style>