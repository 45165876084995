<template>
  <div class="payment">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Pembayaran</div>
    </div>

    <div class="body">
      <div class="group">
        <p class="title">Penerbangan</p>
        <table class="">
          <tr>
            <td>Dari</td>
            <td>:</td>
            <td>{{ booking.kota_asal }}</td>
          </tr>
          <tr>
            <td>Tujuan</td>
            <td>:</td>
            <td>{{ booking.kota_tujuan }}</td>
          </tr>
          <tr>
            <td style="padding-right: 10px">Waktu Terbang</td>
            <td style="padding-right: 6px">:</td>
            <td>{{ booking.tgl_terbang }}</td>
          </tr>
        </table>

        <ul class="detail-penerbangan">
          <li v-for="(item, index) in booking.flight" :key="index">
            <p>
              {{ $validateMaskapai(item.flight_num, booking.airline) }} ({{
                item.flight_num
              }})
            </p>
            <table>
              <tr>
                <td style="width: 80px">{{ item.depart_port }}</td>
                <td>{{ item.depart_time }}</td>
              </tr>
              <tr>
                <td>{{ item.arrive_port }}</td>
                <td>{{ item.arrive_time }}</td>
              </tr>
            </table>
          </li>
        </ul>
      </div>

      <div class="group">
        <p class="title">Penumpang</p>
        <div class="penumpang" v-if="booking.traveller">
          <div>
            <p>Dewasa</p>

            <ul>
              <li v-for="(item, index) in booking.traveller.adult" :key="index">
                {{ item.nama }}
              </li>
            </ul>
          </div>

          <div v-if="booking.traveller.child.length > 0">
            <p>Anak</p>

            <ul>
              <li v-for="(item, index) in booking.traveller.child" :key="index">
                {{ item.nama }}
              </li>
            </ul>
          </div>

          <div v-if="booking.traveller.infant.length > 0">
            <p>Bayi</p>

            <ul>
              <li
                v-for="(item, index) in booking.traveller.infant"
                :key="index"
              >
                {{ item.nama }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="group">
        <p class="title">Total Tagihan</p>
        <h1>{{ booking.grand_total | number }}</h1>
      </div>

      <div class="group">
        <p class="title">Metode Pembayaran</p>
        <p>Silakan lakukan pembayaran ke Rekening berikut</p>

        <p>
          <b>{{ rekening.bank }}</b> <br />
          <b>{{ rekening.no_rekening }}</b> <br />
          <b>{{ rekening.atas_nama }}</b> <br />
        </p>

        <input
          type="text"
          :value="rekening.no_rekening"
          id="no-rek"
          style="opacity: 0; position: absolute; left: -100px"
        />
        <span style="border-bottom: 1px solid #333" @click="copyRek">
          Salin No Rekening
        </span>

        <div
          style="margin: 30px 0; border-top: 1px solid #ccc; padding-top: 20px"
          v-show="showUpload"
        >
          <form @submit.prevent="upload()">
            <div class="form-group">
              <label>Pilih bukti pembayaran</label>
              <input type="file" accept="image/jpg" id="bukti-pembayaran" />
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn"
                style="width: 100%"
                :disabled="loadingBayar"
              >
                {{ loadingBayar ? "Uploading.." : "Upload bukti pembayaran" }}
              </button>
            </div>
          </form>
        </div>

        <button
          v-show="!showUpload"
          @click="showUpload = true"
          style="margin-top: 30px"
        >
          KONFIRMASI PEMBAYARAN
        </button>
      </div>
    </div>

    <div class="modal" v-if="payment.order_id">
      <div class="modal-body">
        <h3 style="text-align: center">Bayar Sebelum :</h3>
        <h1 style="text-align: center">
          {{ moment(payment.expired).format("DD-MM-YY HH:mm") }}
        </h1>

        <p style="text-align: center" v-if="payment.bank == 'bca'">
          <img src="/static/icons/bca.jpg" width="60%" />
        </p>
        <p style="text-align: center" v-if="payment.bank == 'bni'">
          <img src="/static/icons/bni.jpg" width="60%" />
        </p>
        <p style="text-align: center" v-if="payment.bank == 'mandiri'">
          <img src="/static/icons/mandiri.jpg" width="70%" />
        </p>

        <p style="text-align: center">
          Bayar dengan Virtual Account
          <span style="text-transform: uppercase">{{ payment.bank }}</span
          >, pembayaran akan dicek otomatis. <br />
          No Virtual Account :
        </p>
        <h1 style="text-align: center" v-if="payment.bank == 'mandiri'">
          {{ payment.permata_va_number }} <br />
          <input
            type="text"
            :value="payment.permata_va_number"
            id="no-va"
            style="opacity: 0"
          />
        </h1>
        <h1 style="text-align: center" v-else>
          {{ payment.va_numbers[0].va_number }} <br />
          <input
            type="text"
            :value="payment.va_numbers[0].va_number"
            id="no-va"
            style="opacity: 0"
          />
        </h1>
        <p
          style="
            text-align: center;
            text-decoration: underline;
            margin-top: -30px;
          "
          @click="copyText"
        >
          Salin no VA
        </p>

        <div style="display: flex; justify-content: center; margin-top: 30px">
          <router-link :to="'/history/' + booking.id" class="btn">
            Cek Status Pembayaran
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "./../api/FlightApi";
import axios from "axios";
import moment from "moment";

export default {
  name: "Search",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loading: false,
      loadingBayar: false,
      error: false,
      booking: {},
      pembayaran: "",
      payment: {},
      rekening: {},
      showUpload: false,
    };
  },
  methods: {
    moment,
    bayar() {
      const param = {
        id_booking: this.booking.id,
        payment_type: "bank_transfer",
        transaction_details: {
          gross_amount: this.booking.grand_total,
          order_id: this.booking.id,
        },
        bank_transfer: {
          bank: this.pembayaran,
        },
      };

      this.loading = true;
      this.error = false;
      api
        .postJson("/v1/PaymentController/charge", param)
        .then((r) => {
          this.loading = false;
          this.$store.commit("flights/setPayment", r);
          this.payment = r;
        })
        .catch((e) => {
          this.error = true;
          this.loading = false;
        });
    },
    copyText() {
      const copyText = document.getElementById("no-va");

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      document.execCommand("copy");

      alert("No VA disalin: " + copyText.value);
    },
    copyRek() {
      const copyText = document.getElementById("no-rek");

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      document.execCommand("copy");

      alert("No VA disalin: " + copyText.value);
    },
    getRekening() {
      api.get("/v1/RekeningController/get").then((r) => {
        this.rekening = r.data;
      });
    },
    upload() {
      this.loadingBayar = true;

      const data = new FormData();
      data.append("id_booking", this.booking.id);
      data.append(
        "bukti_pembayaran",
        document.getElementById("bukti-pembayaran").files[0]
      );

      axios
        .post(
          "https://apitama.xyz/flight-api/index.php/api/v1/BookingController/uploadBuktiPembayaran",
          data
        )
        .then((res) => {
          this.loadingBayar = false;

          alert("Bukti pembayaran berhasil diupload");
          this.$router.push("/history");
        })
        .catch((err) => {
          this.loadingBayar = false;
          console.log(err.message);
        });
    },
  },
  mounted() {
    this.getRekening();

    this.booking = this.$store.state.flights.booking;
    this.payment = this.$store.state.flights.payment;
  },
};
</script>

<style scoped lang="scss">
.payment {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    background: #eee;

    table {
      border-collapse: collapse;
      td {
        padding: 5px 0px;
        font-size: 14px;
      }
    }

    .group {
      background: #fff;
      padding: 15px;
      box-shadow: 0px 2px 3px #ccc;
      margin-bottom: 13px;
      p.title {
        margin: 0;
        color: var(--primary);
        font-weight: 500;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .detail-penerbangan {
      p {
        margin: 0;
        font-weight: 600;
        margin-bottom: 10px;
      }

      padding-left: 20px;
      li {
        font-size: 14px;
      }
    }

    .penumpang {
      font-size: 14px;
      p {
        margin: 0;
        font-weight: 500;
      }

      ul {
        display: block;
        padding-left: 20px;
        margin: 0;
        margin-bottom: 10px;

        li {
          text-transform: uppercase;
        }
      }
    }

    .tagihan {
      border: 1px solid var(--primary);
      border-radius: 6px;
      margin-top: 30px;
      padding: 15px;

      p,
      h1 {
        margin: 0;
      }
    }

    .pembayaran {
      p {
        margin: 0;
        font-weight: 600;
        margin-bottom: 10px;
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          label {
            display: block;
            padding: 10px 0px;
            border-bottom: 1px solid #ccc;
            &:active {
              background: #eee;
            }
          }
        }
      }
    }
  }

  button,
  .btn {
    background: var(--primary);
    color: #fff;
    padding: 15px;
    width: calc(100%);
    text-align: center;
    text-transform: uppercase;
    outline: none;
    border: 0;
    margin-top: 15px;
    border-radius: 6px;
    margin-bottom: 30px;
    font-weight: 600;
    text-decoration: none;
  }

  .btn {
    font-size: 14px;
    margin: 0 auto;
    width: 220px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;

    .modal-body {
      margin: 15px;
    }
  }
  button:disabled {
    opacity: 0.6;
  }
}
</style>
