<template>
  <div class="ujian">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>UJIAN ONLINE</div>
    </div>

    <div class="jam-berakhir" v-if="showSoal">
      <p>
        {{ jamBerakhir + ":" + menitBerakhir + ":" + detikBerakhir }}
      </p>
    </div>

    <div class="body">
      <div style="text-align:center;" v-if="loadingData">
        <loading />
      </div>

      <!-- 1. Kalo Belom Bayar -->
      <div v-if="tidakBolehUjian" style="text-align: center">
        <h3>{{ invalidMessage }}</h3>
      </div>


      <div class="penilaian" v-if="showPenilaian">
        <h3>Penilaian Manajemen</h3>
        <p class="notif">
          Penilaian untuk manajemen hanya akan dilihat oleh owner, dan nama siswa akan disembunyikan.
        </p>
        <ul>
          <li v-for="item, index in dataManajemen" :key="index">
            <div class="user">
              <div>
                <img :src="item.foto || 'https://img.icons8.com/?size=64&id=0j6tDxtI4hv1&format=png'">
              </div>
              <div>
                <p>{{ item.nama }}</p>
                <small>{{ item.jabatan }}</small>

                <div class="rating">
                  <span v-for="st in stars" :class="{ 'filled': st <= item.rating }" @click="rate(st, item)">
                    ★
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <small>
          <b>Penilaian rating bintang:</b> <br />
          Bintang 1 : Sangat buruk <br />
          Bintang 2 : Buruk <br />
          Bintang 3 : Cukup baik <br />
          Bintang 4 : Baik <br />
          Bintang 5 : Sangat baik <br />
        </small>

        <br />

        <h3>Kritik & Saran</h3>
        <form>
          <div class="form-group">
            <textarea rows="5" v-model="kritikSaran"></textarea>
          </div>
        </form>

        <button type="button" class="btn-primary" :disabled="this.loadingButtonPenilaian"
          @click="submitAll()">SUBMIT</button>
      </div>

      <!-- 1. Kalo ga ada jadwal -->
      <div v-if="tidakAdaUjian" style="text-align: center">
        <h3>Tidak ada Jadwal Ujian</h3>
      </div>

      <!-- 2. Ada jadwal tapi belum mulai -->
      <div style="text-align: center" v-if="ujianBelumDimulai || ujianDimulai">
        <h3>{{ ujian.jadwal.nama }}</h3>
        <p>
          {{ moment(ujian.jadwal.tgl_ujian).format("DD MMM YYYY") }} <br />
          {{
        moment(
          ujian.jadwal.tgl_ujian + " " + ujian.jadwal.jam_mulai
        ).format("HH:mm")
      }}
          -
          {{
          moment(
            ujian.jadwal.tgl_ujian + " " + ujian.jadwal.jam_selesai
          ).format("HH:mm")
        }}
        </p>

        <br />
        <p>Dimulai dalam</p>

        <h1 v-if="ujianBelumDimulai">
          {{ jam + ":" + menit + ":" + detik }}
        </h1>
        <h1 v-if="ujianDimulai">
          Sudah dimulai
        </h1>

        <div class="ketentuan">
          <p>Ketentuan Ujian Online:</p>
          <ul>
            <li>Waktu mengerjakan adalah 60 menit</li>
            <li>Soal akan tampil ketika countdown berakhir</li>
            <li>
              Jangan refresh halaman ketika sudah mulai ujian atau jawaban akan
              ter-reset
            </li>
            <li>
              Jika waktu ujian sudah habis, jawaban akan otomatis tersubmit
            </li>
          </ul>
        </div>

        <button type="button" v-if="ujianDimulai" @click="showSoal = true; ujianDimulai = false;">
          LANJUT KE SOAL UJIAN
        </button>
      </div>

      <!-- 3. Ada jadwal dan sudah mulai -->
      <div class="soal" v-if="showSoal">
        <div v-for="(item, index) in ujian.soal" :key="index">
          <p>
            <b>{{ index + 1 }}. {{ item.soal }}</b>
          </p>
          <ul class="jawaban">
            <li>
              <input type="radio" :id="'soal-a-' + item.id_soal" v-model="jawabanDipilih[item.id_soal]" value="A" />
              <label :for="'soal-a-' + item.id_soal">
                {{ item.pilihan_a }} &nbsp;
              </label>
            </li>
            <li>
              <input type="radio" :id="'soal-b-' + item.id_soal" v-model="jawabanDipilih[item.id_soal]" value="B" />
              <label :for="'soal-b-' + item.id_soal">
                {{ item.pilihan_b }} &nbsp;
              </label>
            </li>
            <li>
              <input type="radio" :id="'soal-c-' + item.id_soal" v-model="jawabanDipilih[item.id_soal]" value="C" />
              <label :for="'soal-c-' + item.id_soal">
                {{ item.pilihan_c }} &nbsp;
              </label>
            </li>
            <li>
              <input type="radio" :id="'soal-d-' + item.id_soal" v-model="jawabanDipilih[item.id_soal]" value="D" />
              <label :for="'soal-d-' + item.id_soal">
                {{ item.pilihan_d }} &nbsp;
              </label>
            </li>
            <li>
              <input type="radio" :id="'soal-e-' + item.id_soal" v-model="jawabanDipilih[item.id_soal]" value="E" />
              <label :for="'soal-e-' + item.id_soal">
                {{ item.pilihan_e }} &nbsp;
              </label>
            </li>
          </ul>
        </div>

        <button type="button" class="btn btn-primary" :disabled="buttonLoading" @click="submitJawaban">
          LANJUT
        </button>
      </div>

      <div class="angket" v-if="showAngket">
        <h3>ANGKET KEPUASAN SISWA TERHADAP PELAYANAN AKADEMIK</h3>
        <ul>
          <li>
            Berilah penilaian secara jujur, objektif, dan penuh tanggung jawab.
          </li>
          <li>
            Informasi yang Saudara berikan hanya akan dipergunakan dalam proses penilaian kinerja dan tidak akan
            berpengaruh terhadap status Saudara sebagai Siswa.
          </li>
        </ul>
        <div class="group" v-for="key, index in Object.keys(dataAngket)" :key="index">
          <p>
            <b>{{ key }}. {{ dataAngket[key][0].judul_group }}</b>
          </p>
          <div v-for="item, index2 in dataAngket[key]" :key="index2">
            <p style="font-weight: 500;">
              {{ index2 + 1 }}. {{ item.soal }}
            </p>

            <ul class="jawaban-angket">
              <li v-if="item.pilihan_a == 'freetext'">
                <div>
                  <input type="text" v-model="jawabanAngket[item.id]" :name="`jawaban-${item.id}`" autocomplete="off"
                    placeholder="Pisahkan dengan koma" />
                </div>
              </li>

              <li v-if="item.pilihan_a != 'freetext'">
                <div>
                  <input type="radio" :id="'angket-a-' + item.id" v-model="jawabanAngket[item.id]"
                    :value="item.pilihan_a" />
                  <label :for="'angket-a-' + item.id">
                    {{ item.pilihan_a }} &nbsp;
                  </label>
                </div>
              </li>
              <li>
                <div>
                  <input type="radio" :id="'angket-b-' + item.id" v-model="jawabanAngket[item.id]"
                    :value="item.pilihan_b" />
                  <label :for="'angket-b-' + item.id">
                    {{ item.pilihan_b }} &nbsp;
                  </label>
                </div>
              </li>
              <li v-if="item.pilihan_c">
                <div>
                  <input type="radio" :id="'angket-c-' + item.id" v-model="jawabanAngket[item.id]"
                    :value="item.pilihan_c" />
                  <label :for="'angket-c-' + item.id">
                    {{ item.pilihan_c }} &nbsp;
                  </label>
                </div>
              </li>
              <li v-if="item.pilihan_d">
                <div>
                  <input type="radio" :id="'angket-d-' + item.id" v-model="jawabanAngket[item.id]"
                    :value="item.pilihan_d" />
                  <label :for="'angket-d-' + item.id">
                    {{ item.pilihan_d }} &nbsp;
                  </label>
                </div>
              </li>
              <li v-if="item.pilihan_e">
                <div>
                  <input type="radio" :id="'angket-e-' + item.id" v-model="jawabanAngket[item.id]"
                    :value="item.pilihan_e" />
                  <label :for="'angket-e-' + item.id">
                    {{ item.pilihan_e }} &nbsp;
                  </label>
                </div>
              </li>
            </ul>

            <br />

          </div>
        </div>

        <button type="button" class="btn btn-primary" :disabled="loadingButtonAngket" @click="submitAngket">
          KIRIM JAWABAN
        </button>
      </div>

      <!-- 4. Ujian Sudah berakhir -->
      <div v-if="ujianBerakhir" style="text-align: center">
        <h3>Ujian sudah berakhir</h3>
      </div>

      <!-- Berhasil submit hasil ujian -->
      <div style="text-align: center" v-if="berhasilUjian">
        <p>
          <img src="./../assets/icons/checked.svg" width="100" />
        </p>
        <h3>Terimakasih telah mengikuti ujian</h3>
        <p>Anda bisa melihat hasil ujian di menu Nilai Ujian</p>

        <a href="javascript:;" @click="$router.go(-1)">Kembali</a>
      </div>
      <!-- 4. Ada jadwal dan sudah selesai (Auto Submit) -->
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "../api/Api";
import moment from "moment";
import _ from 'lodash';

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      tidakAdaUjian: false,
      tidakBolehUjian: false,
      invalidMessage: "",
      ujianBelumDimulai: false,
      ujianDimulai: false,
      showSoal: false,
      ujianBerakhir: false,
      berhasilUjian: false,
      ujian: {
        jadwal: {},
        soal: [],
      },
      jawabanDipilih: [],
      jam: "",
      menit: "",
      detik: "",
      jamBerakhir: "00",
      menitBerakhir: "00",
      detikBerakhir: "00",
      buttonLoading: false,
      sudahSubmit: false,
      loadingData: true,

      dataManajemen: [],
      kritikSaran: '',
      stars: [
        1, 2, 3, 4, 5
      ],

      showPenilaian: false,
      showAngket: false,

      loadingButtonPenilaian: false,
      loadingButtonAngket: false,

      submitJawabanSuccess: false,

      dataAngket: {},
      soalAngket: [],
      jawabanAngket: []
    };
  },
  methods: {
    moment,
    rate(star, item) {
      item.rating = star;
      this.$forceUpdate();
    },
    async loadData() {
      const r = await api
        .get(
          "/v1/siswa/UjianController/getJadwalTerdekat?id_siswa=" +
          this.$store.state.settings.user.id
        )

      this.loadingData = false
      if (r.valid) {
        this.ujian = r.data;

        const mulai_dalam = parseInt(this.ujian.jadwal.dimulai_dalam);
        const selesai_dalam = parseInt(this.ujian.jadwal.berakhir_dalam);

        // cek udah submit jawaban belom
        if (parseInt(this.ujian.sudah_ujian) > 0 && this.ujian.sudah_angket <= 0) {
          this.showPenilaian = true;
          // this.showAngket = true;
        } else if (parseInt(this.ujian.sudah_ujian) > 0 && parseInt(this.ujian.sudah_angket) > 0) {
          this.berhasilUjian = true;
        }

        // ujian udah selesai
        else if (mulai_dalam <= 0 && selesai_dalam <= 0) {
          this.ujianBerakhir = true;
        }
        // ini berarti ujian sedang berlangsung
        else if (mulai_dalam <= 0 && selesai_dalam > 0) {
          this.ujianDimulai = true;
          this.countDownBerakhir(this.ujian.jadwal.berakhir_dalam);
        }
        // ini berarti belum mulai & belum selesai
        else if (mulai_dalam > 0 && selesai_dalam > 0) {
          this.ujianBelumDimulai = true;
          this.countDown(this.ujian.jadwal.dimulai_dalam);
        }

        this.ujian.soal.forEach((item) => {
          item.jawaban_dipilih = null;
        });

        this.loadAngket();
      } else {
        if (r.tidak_ada_jadwal) {
          this.tidakAdaUjian = true;
        } else if (r.tidak_boleh_ujian) {
          this.tidakBolehUjian = true;
        }
        this.invalidMessage = r.message;
      }
    },
    countDown(dimulaiDalam) {
      var h = Math.floor(dimulaiDalam / 3600);
      var m = Math.floor((dimulaiDalam % 3600) / 60);
      var s = Math.floor((dimulaiDalam % 3600) % 60);

      this.jam = h < 10 ? "0" + h : h;
      this.menit = m < 10 ? "0" + m : m;
      this.detik = s < 10 ? "0" + s : s;

      if (dimulaiDalam <= 0) {
        this.ujianBelumDimulai = false;
        this.ujianDimulai = false;
        this.showSoal = true;
        this.countDownBerakhir(this.ujian.jadwal.berakhir_dalam);
        return;
      }

      setTimeout(() => {
        this.countDown(dimulaiDalam - 1);
      }, 1000);
    },
    countDownBerakhir(berakhirDalam) {
      var h = Math.floor(berakhirDalam / 3600);
      var m = Math.floor((berakhirDalam % 3600) / 60);
      var s = Math.floor((berakhirDalam % 3600) % 60);

      this.jamBerakhir = h < 10 ? "0" + h : h;
      this.menitBerakhir = m < 10 ? "0" + m : m;
      this.detikBerakhir = s < 10 ? "0" + s : s;

      if (berakhirDalam <= 0) {
        if (!this.sudahSubmit) {
          this.submitJawaban();
        }
        return;
      }

      setTimeout(() => {
        this.countDownBerakhir(berakhirDalam - 1);
      }, 1000);
    },
    submitJawaban() {

      let jawaban = [];
      Object.keys(this.jawabanDipilih).forEach((item) => {
        jawaban.push({
          id_soal: item,
          jawaban: this.jawabanDipilih[item],
        });
      });

      const payload = {
        id_jadwal_ujian: this.ujian.jadwal.id,
        id_siswa: this.$store.state.settings.user.id,
        jawaban: jawaban,
      };

      this.buttonLoading = true;
      api
        .postJson("/v1/siswa/UjianController/submit", payload)
        .then((r) => {
          this.ujianDimulai = false;

          this.showSoal = false;
          this.showPenilaian = true;
          // this.showAngket = true;

          this.buttonLoading = false;

          this.submitJawabanSuccess = true;
        })
        .catch((e) => {
          this.buttonLoading = false;
        });
    },
    submitAll() {

      let belumDinilai = false;
      this.dataManajemen.forEach((item) => {
        if (item.rating == 0) {
          belumDinilai = true;
        }
      })

      if (belumDinilai) {
        alert('Silakan klik pada bintang untuk menilai manajemen');
        return;
      }
      if (!this.kritikSaran) {
        alert('Silakan input kritik & saran');
        return;
      }

      const payload = {
        id_siswa: this.$store.state.settings.user.id,
        id_ujian_online: this.ujian.jadwal.id || -1,
        penilaian: this.dataManajemen,
        kritik_saran: this.kritikSaran
      }

      this.loadingButtonPenilaian = true;
      api
        .postJson("/v1/siswa/UjianController/submitPenilaian", payload)
        .then((r) => {
          this.showPenilaian = false;
          this.sudahSubmit = true;
          this.berhasilUjian = true;

          this.loadingButtonPenilaian = false;
        })
        .catch((e) => {
          this.loadingButtonPenilaian = false;
        });

    },
    async loadManajemen() {
      const res = await api.get('/v1/manajemen/get?id_kampus=' + this.$store.state.settings.user.id_kampus);
      this.dataManajemen = res.data
      this.dataManajemen.forEach((item) => {
        item.rating = 0;
      })
    },
    async loadAngket() {
      const res = await api.get('/v1/siswa/UjianController/getAngket?id_siswa=' + this.$store.state.settings.user.id + '&ujian=' + this.ujian.jadwal.nama);
      this.soalAngket = res.data;
      this.dataAngket = _.groupBy(res.data, 'group');
    },
    async submitAngket() {
      const payload = [];
      Object.keys(this.jawabanAngket).forEach((key) => {
        payload.push({
          id_siswa: this.$store.state.settings.user.id,
          id_angket: key,
          id_ujian: this.ujian.jadwal.id,
          nama: this.$store.state.settings.user.nama,
          kampus: this.$store.state.settings.user.kampus,
          angkatan: this.$store.state.settings.user.angkatan,
          jurusan: this.$store.state.settings.user.jurusan,
          soal: this.soalAngket.find((k) => k.id == key).soal,
          jawaban: this.jawabanAngket[key]
        })
      })

      this.loadingButtonAngket = true;

      const res = await api.postJson('/v1/siswa/UjianController/submitAngket', payload);

      if (res.valid) {
        this.showAngket = false;
        this.sudahSubmit = true;
        this.berhasilUjian = true;

        this.loadingButtonAngket = false;
      }
    }
  },
  mounted() {
    this.loadData();
    this.loadManajemen();
  },
};
</script>

<style scoped lang="scss">
.ujian {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    background: #fff;
    padding: 15px;

    .soal {
      p {
        margin: 0;
      }
    }

    .jawaban {
      margin: 0 0 30px;
      list-style-type: none;
      padding-left: 0px;

      li {
        border-bottom: 1px solid #ccc;
        position: relative;

        input {
          position: absolute;
          left: 0;
          margin-top: 15px;
        }

        label {
          display: block;
          padding: 10px 0px 10px 30px;
        }

        &:active {
          background: #eee;
        }

        &.selected {
          background: var(--primary);
          color: #fff;
        }
      }
    }

    button {
      width: 100%;
      background: var(--primary);
      color: #fff;
      padding: 14px;
      border: 0;
      border-radius: 4px;
      outline: none;
      font-weight: 700;
      text-transform: uppercase;

      &:disabled {
        background: #79dcf8;
      }
    }
  }

  .jam-berakhir {
    position: fixed;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 1;

    p {
      margin: 0;
      padding: 10px 15px;
    }
  }

  .ketentuan {
    text-align: left;

    p {
      margin-left: 20px;
      font-weight: bold;
    }
  }
}

.penilaian {
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-bottom: 20px;

      .user {
        display: flex;

        img {
          width: 100px;
          height: 60px;
          object-fit: contain;
        }

        >div {
          &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        p {
          margin: 0;
          font-weight: bold;
        }
      }
    }
  }

  .rating {
    font-size: 24px;
  }

  .rating span {
    cursor: pointer;
  }

  .rating .filled {
    color: gold;
  }
}

p.notif {
  font-size: 14px;
  font-style: italic;
  margin-top: 0;
}

.angket {
  .group {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.jawaban-angket {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    gap: 10px;
    border-bottom: 1px solid #ccc;

    &:active {
      background: #eee;
    }

    >div {
      display: flex;
      align-items: center;
      gap: 10px;

      input[type="text"] {
        margin-bottom: 15px;
      }

      &:last-child {
        width: 100%;
      }
    }

    label {
      display: block;
      padding: 15px 0;
      width: 100%;
    }
  }

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;

    &[type="text"] {
      width: 100%;
    }

    &:focus {
      border: 1px solid var(--primary);
    }
  }
}
</style>
