<template>
  <div class="sertifikat">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Pengajuan Sertifikat</div>
    </div>

    <div class="body">
      <div class="loading" style="display: flex; justify-content: center; padding: 60px 0" v-if="loadingData">
        <loading />
      </div>
      <div v-else>
        <div class="no-akses" v-if="noAkses">
          <div v-html="message"></div>
        </div>
        <div v-else>
          <div>
            <p class="notif" v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'">
              Silakan perbarui informasi berikut untuk pengajuan pengiriman
              sertifikat
            </p>

            <form autocomplete="off" @submit.prevent="save">
              <table class="table-form" :class="status == '' ? '' : 'vtop'">
                <!-- <tr>
              <td valign="top">Jenis Sertifikat</td>
              <td>
                <select multiple style="height: 220px;">
                  <option :value="item" v-for="item, index in jenisSertifikat" :key="index">
                    {{ item }}
                  </option>
                </select>
              </td>
            </tr> -->
                <tr>
                  <td>Nama Penerima</td>
                  <td>
                    <input type="text" v-model="form.nama_lengkap"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'" />
                    <b v-else>
                      {{ data.nama_penerima }}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>No Telp Aktif</td>
                  <td>
                    <input type="tel" v-model="form.no_telp"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'" />
                    <b v-else>
                      {{ data.no_telp }}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td valign="top" style="padding-top: 6px">
                    Alamat Pengiriman
                  </td>
                  <td valign="top">
                    <textarea v-model="form.alamat_pengiriman" rows="3"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'"></textarea>
                    <b v-else>{{ data.alamat_pengiriman }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>
                    <select v-model="form.id_provinsi" @change="loadKabupaten"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'">
                      <option value=""></option>
                      <option :value="item.id" v-for="(item, index) in lokasi.provinsi" :key="index">
                        {{ item.nama }}
                      </option>
                    </select>
                    <b v-else>{{ data.provinsi }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Kabupaten</td>
                  <td>
                    <select v-model="form.id_kabupaten" @change="loadKecamatan"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'">
                      <option value=""></option>
                      <option :value="item.id" v-for="(item, index) in lokasi.kabupaten" :key="index">
                        {{ item.nama }}
                      </option>
                    </select>
                    <b v-else>{{ data.kabupaten }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>
                    <select v-model="form.id_kecamatan" @change="loadKelurahan"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'">
                      <option value=""></option>
                      <option :value="item.id" v-for="(item, index) in lokasi.kecamatan" :key="index">
                        {{ item.nama }}
                      </option>
                    </select>
                    <b v-else>{{ data.kecamatan }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>
                    <select v-model="form.id_kelurahan" @change="loadKodepos"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'">
                      <option value=""></option>
                      <option :value="item.id + '|' + item.kode_pos" v-for="(item, index) in lokasi.kelurahan"
                        :key="index">
                        {{ item.nama }}
                      </option>
                    </select>
                    <b v-else>{{ data.kelurahan }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Kode Pos</td>
                  <td>
                    <input type="number" v-model="form.kodepos"
                      v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'" />
                    <b v-else>{{ data.kodepos }}</b>
                  </td>
                </tr>
                <tr v-if="status == '' || status == 'DITOLAK' || status == 'BELUM_VALIDASI'">
                  <td colspan="2">
                    <button type="submit" class="btn" v-if="!loading">
                      Ajukan Pengiriman Sertifikat
                    </button>
                    <p style="text-align: center" v-if="loading">
                      <loading />
                    </p>
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div class="history" v-if="
            status == 'PENDING' ||
            status == 'VALIDASI' ||
            status == 'DIKIRIM' ||
            status == 'DITOLAK' ||
            status == 'BELUM_VALIDASI' ||
            status == 'RETUR'
          ">
            <p>
              <b> History Pengiriman</b>
            </p>
            <ul>
              <li v-if="status != ''">
                <div>
                  {{ moment(data.tgl_diajukan).format("DD/MM/YYYY") }} <br />
                  {{ moment(data.tgl_diajukan).format("HH:mm") }}
                </div>
                <div>
                  Pengajuan Sertifikat
                  <span v-if="status == 'PENDING'">Menunggu validasi data</span>
                </div>
              </li>
              <li v-if="status == 'DITOLAK'">
                <div>
                  {{ moment(data.tgl_diajukan).format("DD/MM/YYYY") }} <br />
                  {{ moment(data.tgl_diajukan).format("HH:mm") }}
                </div>
                <div>
                  Pengajuan Sertifikat Ditolak
                  <span>Alasan : {{ data.alasan_ditolak }}</span>
                </div>
              </li>
              <li v-if="status != 'PENDING' && status != 'DITOLAK'">
                <div>
                  {{ moment(data.tgl_divalidasi).format("DD/MM/YYYY") }} <br />
                  {{ moment(data.tgl_divalidasi).format("HH:mm") }}
                </div>
                <div>
                  Pengajuan Sertifikat sudah divalidasi
                  <span v-if="status == 'VALIDASI'">Menunggu pengiriman sertifikat</span>
                </div>
              </li>
              <li v-if="status == 'DIKIRIM'" class="dikirim" @click="openPopover">
                <div>
                  {{ moment(data.tgl_dikirim).format("DD/MM/YYYY") }} <br />
                  {{ moment(data.tgl_dikirim).format("HH:mm") }}
                </div>
                <div>
                  Sertifikat sudah dikirim. Klik di sini untuk melihat resi
                  pengiriman
                </div>
              </li>
              <li v-if="status == 'RETUR'">
                <div>
                  {{ moment(data.tgl_diretur).format("DD/MM/YYYY") }} <br />
                  {{ moment(data.tgl_diretur).format("HH:mm") }}
                </div>
                <div>
                  Alasan Retur : {{ data.alasan_retur }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay" :class="{ open: popoverOpen }" @click="popoverOpen = false"></div>
    <div class="popover" :class="{ open: popoverOpen }">
      <div class="popover-body">
        <table class="table-form vtop">
          <tr>
            <td>Ekspedisi</td>
            <td>
              <b>{{ data.ekspedisi }}</b>
            </td>
          </tr>
          <tr>
            <td>No Resi</td>
            <td>
              <b>{{ data.no_resi }}</b>
            </td>
          </tr>
          <tr>
            <td>Bukti Kirim</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2">
              <img :src="data.bukti_pengiriman" width="100%" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import moment from "moment";
import * as api from "./../api/Api";

export default {
  name: "Sertifikat",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loadingStatusbayar: false,
      loadingData: true,
      loading: false,
      status: "",
      data: {},
      statusBayar: "LUNAS", //FIXME:
      noAkses: false,
      message: "",
      form: {
        nama_lengkap: null,
        no_telp: null,
        alamat_pengiriman: null,
        id_provinsi: null,
        id_kabupaten: null,
        id_kecamatan: null,
        id_kelurahan: null,
        kodepos: null,
      },
      lokasi: {
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
        kelurahan: [],
        kodepos: [],
      },
      popoverOpen: false,
      jenisSertifikat: [
        "KPK",
        "Praktek Terbang",
        "Awarness Avsec",
        "Awarness DG",
        "Basic Tranning Avsec ( khusus avsec )",
        "Table manner ( khusus FA )",
        "Make over ( beauty class )",
        "Mock up wetdrill ( khusus FA )",
        "Transkrip nilai",
        "Sertifikat kelulusan",
      ],
    };
  },
  methods: {
    moment,
    cekStatusbayar() {
      this.loadingStatusbayar = true;
      api
        .get(
          "/v1/SertifikatController/cekStatusbayar?id_siswa=" + this.$user.id
        )
        .then((r) => {
          if (r.valid !== true) {
            this.noAkses = true;
            this.message = r.message;
          }
          this.statusBayar = r.data.status_bayar;
          this.loadingStatusbayar = false;
        });
    },
    loadData() {
      this.form.nama_lengkap = this.$user.nama;
      this.form.no_telp = this.$user.no_hp;
      api
        .get("/v1/SertifikatController/get?id_siswa=" + this.$user.id)
        .then((r) => {
          this.loadingData = false;
          if (r.data) {
            this.status = r.data.status;
            this.data = r.data;

            this.form.alamat_pengiriman = this.data.alamat_pengiriman;

            this.form.id_provinsi = this.data.id_provinsi;
            this.loadKabupaten();
            this.form.id_kabupaten = this.data.id_kabupaten;
            this.loadKecamatan();
            this.form.id_kecamatan = this.data.id_kecamatan;
            this.loadKelurahan();
            this.form.id_kelurahan =
              this.data.id_kelurahan + "|" + this.data.kodepos;
            this.form.kodepos = this.data.kodepos;
          }
        });
    },
    loadProvinsi() {
      api.get("/v1/LokasiController/provinsi").then((r) => {
        this.lokasi.provinsi = r;
      });
    },
    loadKabupaten() {
      api
        .get(
          "/v1/LokasiController/kabupaten?id_provinsi=" + this.form.id_provinsi
        )
        .then((r) => {
          this.lokasi.kabupaten = r;
        });
    },
    loadKecamatan() {
      api
        .get(
          "/v1/LokasiController/kecamatan?id_kabupaten=" +
          this.form.id_kabupaten
        )
        .then((r) => {
          this.lokasi.kecamatan = r;
        });
    },
    loadKelurahan() {
      api
        .get(
          "/v1/LokasiController/kelurahan?id_kecamatan=" +
          this.form.id_kecamatan
        )
        .then((r) => {
          this.lokasi.kelurahan = r;
        });
    },
    loadKodepos() {
      this.form.kodepos = this.form.id_kelurahan.split("|")[1];
    },
    save() {
      let valid = true;
      Object.keys(this.form).forEach((k) => {
        if (this.form[k] == null || this.form[k] == "") {
          valid = false;
        }
      });

      if (!valid) {
        alert("Silakan lengkapi form");
        return;
      }

      this.form.id_siswa = this.$user.id;

      const provinsi = this.lokasi.provinsi.find((k) => {
        return k.id == this.form.id_provinsi;
      });
      const kabupaten = this.lokasi.kabupaten.find((k) => {
        return k.id == this.form.id_kabupaten;
      });
      const kecamatan = this.lokasi.kecamatan.find((k) => {
        return k.id == this.form.id_kecamatan;
      });
      const kelurahan = this.lokasi.kelurahan.find((k) => {
        return k.id == this.form.id_kelurahan.split("|")[0];
      });

      this.form.provinsi = this.form.id_provinsi;
      this.form.kabupaten = this.form.id_kabupaten;
      this.form.kecamatan = this.form.id_kecamatan;
      this.form.kelurahan = this.form.id_kelurahan;

      this.loading = true;
      api
        .post("/v1/SertifikatController/request", this.form)
        .then((r) => {
          this.loadData();
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    openPopover() {
      setTimeout(() => {
        this.popoverOpen = true;
      }, 150);
    },
  },
  mounted() {
    this.cekStatusbayar();
    this.loadData();
    this.loadProvinsi();
  },
};
</script>

<style scoped lang="scss">
.sertifikat {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
    z-index: 3;
  }

  .body {
    padding: 75px 15px 15px;
  }

  .no-akses {
    text-align: center;
    margin: 60px 0px;

    p {
      font-size: 14px;
    }
  }

  .notif {
    font-size: 13px;
    background: rgba(255, 239, 93, 0.479);
    margin-top: 0;
    padding: 10px;
    border: 1px solid rgb(250, 227, 100);
    border-radius: 6px;
  }

  .table-form {
    width: 100%;
    border-collapse: collapse;

    &.vtop {
      td {
        padding: 0 !important;
        vertical-align: top;
      }
    }

    td:first-child {
      width: 140px;
      font-size: 14px;
    }

    input,
    select,
    textarea {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      width: calc(100% - 18px);
      outline: none;
      margin-bottom: 5px;
      background: #fff;

      &:focus {
        border: 1px solid var(--primary);
      }

      &.invalid {
        border: 1px solid #f00;
      }
    }

    select {
      width: 100%;
    }

    b {
      font-size: 14px;
      display: block;
      padding-bottom: 15px;
      text-align: right;
      text-transform: uppercase;
    }
  }

  .btn {
    margin-top: 15px;
    background-color: var(--primary);
    color: #fff;
    padding: 15px;
    outline: none;
    border: 0;
    width: 100%;
    display: block;
    border-radius: 50px;
    font-weight: 600;
    text-transform: uppercase;

    &:active {
      background-color: #12a2ca;
    }

    &.loading {
      background-color: #a0eaff;
    }
  }

  .history {
    margin-top: 20px;

    ul {
      list-style-type: none;
      padding-left: 0px;
      font-size: 14px;

      li {
        display: grid;
        grid-template-columns: 100px 1fr;
        padding-bottom: 15px;

        >div:last-child {
          font-weight: 600;
          font-size: 15px;
        }

        span {
          font-size: 14px;
          display: block;
          font-weight: 400;
          color: #777;
        }

        &.dikirim {
          &:active {
            background-color: #eee;
          }
        }
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;

    &.open {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }
  }

  .popover {
    position: fixed;
    bottom: -1000px;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #fff;
    transition: all 0.5s ease;

    .popover-body {
      margin: 15px;
      height: 300px;
      overflow-y: auto;
    }

    &.open {
      bottom: 0;
    }
  }
}
</style>
