const state = {
    param: {},
    selected: {},
    booking: {},    
    airlineList: [],
    airportList: [],
    payment: {},
    result: []
}

const getters = {}

const mutations = {
    setParam(state, param) {        
        state.param = param
    },
    setSelected(state, selected) {
        state.selected = selected
    },
    setBooking(state, param) {
        state.booking = param
    },
    updateBooking(state, param) {
        state.booking.id = param.id
        state.booking.grand_total = param.grand_total
    },
    setAirlineList(state, list) {
        state.airlineList = list
    },
    setAirportList(state, list) {
        state.airportList = list
    },
    setPayment(state, payment) {
        state.payment = payment
    },
    setResult(state, result) {
        state.result.push(result)
    },
    clearResult(state) {
        state.result = []
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
