<template>
  <div class="popover">
    <div id="reader"></div>

    <div class="modal" v-if="text != ''">
      <div class="modal-body">
        <p>PSPP Penerbangan</p>
        <h3>{{ text }}</h3>
        <button type="button" @click="$router.back()">OKE</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';
import * as api from "./../api/Api";

export default {
  data() {
    return {
      user: this.user = this.$store.state.settings.user,

      isStart: false,
      html5QrCode: null,

      cameraSource: [],
      cameraId: null,

      text: "",
    }
  },
  methods: {
    scan(selectedId) {
      this.html5QrCode = new Html5Qrcode("reader");

      if (this.isStart) {
        this.html5QrCode.stop();
      }

      Html5Qrcode.getCameras().then(devices => {
        this.cameraSource = devices;

        if (devices && devices.length) {
          this.cameraId = selectedId ? selectedId : devices[devices.length - 1].id;

          this.html5QrCode.start(
            this.cameraId,
            {
              fps: 10,
              qrbox: { width: 300, height: 300 }
            },
            (decodedText) => {

              const param = {
                id_siswa: this.user.id,
                no_daftar: this.user.no_daftar,
                nama_lengkap: this.user.nama,
                kampus: this.user.kampus,
                angkatan: this.user.angkatan,
                value: decodedText
              }

              api
                .post("/v1/siswa/JadwalController/scan", param)
                .then((r) => {
                  if (r.valid) {
                    if (r.tipe == 'clock_in') {
                      this.text = 'Absen masuk berhasil!';
                    } else {
                      this.text = 'Absen pulang berhasil!';
                    }
                  } else {
                    this.text = r.message;
                  }
                });

              this.isStart = false;

              this.html5QrCode.stop();
            },
            (errorMessage) => {
              // alert("Error parsing: " + errorMessage)
            })
            .catch((err) => {
              alert('Failed: ' + err)
            });
          this.isStart = true;
        }
      }).catch(err => {
        // handle err
        alert('Failed fetching camera: ' + err)
      });
    }
  },
  mounted() {
    this.scan();
  },
  beforeDestroy() {
    this.html5QrCode.stop();
  }
}
</script>

<style lang="scss" scoped>
.popover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;

  display: grid;
  place-items: center;

  #reader {
    width: 100%;
  }
}

.modal-body {
  padding: 20px;

  p {
    margin-top: 0;
  }
}
</style>
