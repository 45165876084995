<template>
  <div class="profil">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Soal-soal</div>
    </div>

    <div class="body">
      <div v-for="(item, index) in soal" :key="index">
        <div class="soal" @click="showJawaban = item.id">
          {{ item.soal }}

          <p class="jawaban" v-if="showJawaban == item.id">
            {{ getJawaban(item) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import * as api from "../api/Api";

export default {
  name: "Tiket",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      soal: [],
      showJawaban: 0
    };
  },
  methods: {
    getJawaban(item) {
      if (item.jawaban == "A") {
        return item.pilihan_a;
      } else if (item.jawaban == "B") {
        return item.pilihan_b;
      } else if (item.jawaban == "C") {
        return item.pilihan_c;
      } else if (item.jawaban == "D") {
        return item.pilihan_d;
      } else if (item.jawaban == "E") {
        return item.pilihan_e;
      }
    },
    toggleJawaban(item) {
      if (!item.showJawaban) {
        item.showJawaban = true;
      } else {
        item.showJawaban = false;
      }
    },
  },
  mounted() {
    api
      .get(
        "/v1/siswa/JadwalController/getSoal?id_siswa=" +
        this.$store.state.settings.user.id
      )
      .then((r) => {
        if (r) {
          r.forEach((item) => {
            item.showJawaban = false;
            this.soal.push(item);
          });
        }
      });
  },
};
</script>

<style scoped lang="scss">
.profil {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    padding: 15px;

    .soal {
      border-bottom: 1px solid #ccc;
      padding: 10px 0px;
      font-size: 14px;

      &:active {
        background: #eee;
      }
    }

    p.jawaban {
      font-weight: 600;
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}
</style>
